
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

import { corporaMixin, truncateMixin } from '@/mixins'
import { useAuthStore } from '@/stores'
import { UUID } from '@/types'

import HeaderActions from '@/components/HeaderActions.vue'

export default defineComponent({
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  components: {
    HeaderActions
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isVerified'])
  }
})
