
import { mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'
import { errorParser } from '@/helpers'
import { useAuthStore } from '@/stores'
import { isAxiosError } from 'axios'

interface FieldErrors {
  error?: string
  email?: string[]
  // eslint-disable-next-line camelcase
  display_name?: string[]
  password?: string[]
  confirmPassword?: string[]
}

export default defineComponent({
  data: () => ({
    email: '',
    displayName: '',
    password: '',
    confirmPassword: '',
    loading: false,
    error: null,
    fieldErrors: {} as FieldErrors
  }),
  computed: {
    ...mapStores(useAuthStore),
    ...mapState(useAuthStore, ['user', 'isLoggedOn']),
    canSubmit () {
      return this.displayName && this.email && this.password && this.confirmPassword && !this.loading
    }
  },
  methods: {
    setErrors (error: unknown) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (!isAxiosError(error) || !error.response || typeof error.response.data !== 'object') this.fieldErrors = { error: errorParser(error) }
      else this.fieldErrors = error.response.data
    },
    async register () {
      if (this.password !== this.confirmPassword) {
        this.fieldErrors = { confirmPassword: ["Passwords don't match"] }
        return
      }

      this.loading = true
      try {
        await this.authStore.register({
          email: this.email,
          password: this.password,
          display_name: this.displayName
        })
      } catch (err) {
        this.setErrors(err)
      } finally {
        this.loading = false
      }
    },
    tryRedirect () {
      if (!this.isLoggedOn) return
      if (typeof this.$route.query?.next === 'string') this.$router.push(this.$route.query.next)
      else this.$router.push({ name: 'corpus-list' })
    }
  },
  watch: {
    // Use a watched property in case another request runs and updates the current user
    user: {
      handler: 'tryRedirect',
      immediate: true
    }
  }
})
