
import { isAxiosError } from 'axios'
import { capitalize, isEqual } from 'lodash'
import { mapState, mapActions, mapStores } from 'pinia'

import { NAVIGATION_PAGE_SIZES, DEFAULT_PAGE_SIZE } from '@/config'
import { getNavigationFilters } from '@/filterbar'
import { ensureArray, errorParser } from '@/helpers'
import { corporaMixin } from '@/mixins'
import {
  useDisplayStore,
  useClassificationStore,
  useElementStore,
  useNavigationStore,
  useNotificationStore
} from '@/stores'

import Paginator from '@/components/Paginator.vue'
import ElementList from './ElementList.vue'
import FilterBar from './FilterBar'
import { NavigationFilters } from '@/stores/navigation'
import { ElementOrdering, OrderDirection } from '@/api'
import { LocationQuery } from 'vue-router'
import { defineComponent, PropType } from 'vue'
import { UUID } from '@/types'

export default defineComponent({
  emits: ['update:query'],
  mixins: [
    corporaMixin
  ],
  components: {
    Paginator,
    FilterBar,
    ElementList
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true
    },
    elementId: {
      type: String as PropType<UUID>,
      default: null
    },
    query: {
      type: Object as PropType<LocationQuery>,
      required: true
    }
  },
  data: () => ({
    // Filters that have been typed; they may not be applied if the user has not pressed Enter yet.
    selectedFilters: {} as NavigationFilters,
    pageNumber: 1,
    loading: false,
    error: null as string | string[] | Record<string, string | string[]> | null,

    /*
     * Beware updating those values as they immediately trigger onFilterUpdate
     * and may create race-conditions
     */
    order: 'name' as ElementOrdering,
    orderDirection: 'asc' as OrderDirection
  }),
  computed: {
    ...mapStores(useNavigationStore),
    ...mapState(useElementStore, ['elementsUpdate']),
    ...mapState(useDisplayStore, ['navigationPageSize', 'displayDetails', 'displayElementClasses']),
    ...mapState(useClassificationStore, ['classifications']),
    availableFilters () {
      return getNavigationFilters(this.selectedFilters)
    },
    /**
     * Parses an API error into an array of objects, optionally with relevant filter names if they exist.
     */
    parsedErrors (): { name: string | null; messages: string[] }[] {
      if (!this.error) return []
      if (typeof this.error === 'string') return [{ name: null, messages: [this.error] }]
      if (Array.isArray(this.error)) return [{ name: null, messages: this.error }]

      const { detail, non_field_errors: nonFieldErrors, __all__: all, ...specificErrors } = this.error
      const errors = []

      /*
       * Some general errors are returned in the `detail`, `non_field_errors` or `__all__` keys.
       * We display each of those errors as a separate list item with no filter name
       */
      if (detail || nonFieldErrors || all) {
        errors.push(...([
          ...ensureArray(detail),
          ...ensureArray(nonFieldErrors),
          ...ensureArray(all)
        ].map(message => ({ name: null, messages: [message] }))))
      }

      // Other errors are assumed to be filter names
      if (specificErrors) {
        // Build a map of filter keys to display names
        const filterNames = Object.fromEntries([
          ...this.availableFilters.map(filter => [filter.name, filter.displayName]),
          ...this.availableFilters.filter(filter => filter.operatorName).map(filter => [filter.operatorName, `${filter.displayName} operator`])
        ])
        errors.push(...Object.entries(specificErrors).map(([key, value]) => ({
          // Try to find a display name from the filter bar filters, and fall back to pretty-printing the keys returned by the API
          name: filterNames[key] || capitalize(key.replaceAll('_', ' ')),
          messages: ensureArray(value)
        })))
      }

      return errors
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useDisplayStore, ['setPageSize']),
    toggleOrderDirection () {
      // Do not allow toggling the order direction for a random order, as it makes no sense
      if (this.order === 'random') return
      this.orderDirection = this.orderDirection === 'asc' ? 'desc' : 'asc'
    },
    // The submit event of the FilterBar. Reset the page number to 1 before making a new query
    submitFilters () {
      this.pageNumber = 1
      this.onFilterUpdate()
    },
    async onFilterUpdate () {
      /*
       * Trigger new queries when the list filters get updated
       * Ignore queries if the corpus is not available
       * or if we use the selection, as it is already in the store
       */
      if (!this.corpusId) return

      // Build the new route query
      const query = {
        ...this.selectedFilters
      }
      if (this.navigationPageSize !== null && this.navigationPageSize !== DEFAULT_PAGE_SIZE) query.page_size = this.navigationPageSize.toString()
      if (query.recursive === 'false') delete query.recursive
      if (this.pageNumber !== 1) query.page = this.pageNumber.toString()
      /*
       * Only include the order and order direction if they do not match the expected defaults.
       * This avoid cases where accessing /element/<id>/ immediately redirects to /element/<id>?order=…,
       * which then gets the user stuck in a redirection loop.
       */
      if (this.order && this.order !== (this.elementId ? 'position' : 'name')) query.order = this.order
      if (this.order !== 'random' && this.orderDirection !== 'asc') query.order_direction = this.orderDirection

      /*
       * If this is not the same route, update the route.
       * We have watchers in place on the route; when the route updates,
       * this method will be called again and then we can run the query.
       */
      if (!isEqual(query, this.query)) {
        this.$emit('update:query', query)
        return
      }

      // Clear this.error when filters are updated or submitted again
      if (this.error) this.error = null
      this.loading = true
      try {
        await this.navigationStore.list({
          ...this.selectedFilters,
          page: this.pageNumber.toString(),
          order: this.order,
          order_direction: this.orderDirection
        })
      } catch (err) {
        // Both arrays and objects will have a `typeof` that returns `object`
        if (isAxiosError(err) && typeof err.response?.data === 'object') this.error = err.response.data
        // Leave all the other cases to errorParser, as it can handle basic Axios error messages and any JS exception
        else this.error = errorParser(err)
      } finally {
        this.loading = false
      }
    },
    async silentReload () {
      this.loading = true
      try {
        await this.navigationStore.list()
      } catch (err) {
        this.notify({ type: 'error', text: `An error occurred while updating the elements list: ${errorParser(err)}` })
      } finally {
        this.loading = false
      }
    },
    updateIds () {
      this.navigationStore.corpusId = this.corpusId
      this.navigationStore.elementId = this.elementId
      // Reset to page 1 if the user switches to another element or corpus
      this.pageNumber = 1
    },
    parseQuery (query: LocationQuery) {
      // Clone the query to avoid mutating the original argument, and validate query parameter types
      const newQuery: Record<string, string> = {}
      for (const key in query) {
        // Handle arrays by only using the last item, since we do not have array parameters and the API ignores everything but the last value
        const value = Array.isArray(query[key]) ? query[key][query[key].length - 1] : query[key]
        // Ignore nulls so we only have strings
        if (value !== null) newQuery[key] = value
      }

      // If there is a page size in the URL, store it, otherwise reset to the default size
      let pageSize = DEFAULT_PAGE_SIZE
      if (newQuery.page_size) {
        pageSize = Number.parseInt(newQuery.page_size, 10)
        // Reject invalid page sizes
        if (!NAVIGATION_PAGE_SIZES.includes(pageSize)) pageSize = DEFAULT_PAGE_SIZE
      }
      if (this.navigationPageSize !== pageSize) this.setPageSize(pageSize)
      if (newQuery.page_size) delete newQuery.page_size

      if (newQuery.page) {
        const page = Number.parseInt(newQuery.page, 10)
        if (Number.isInteger(page) && page > 0) this.pageNumber = page
        delete newQuery.page
      } else this.pageNumber = 1

      if (newQuery.order) {
        const allowedOrders = ['name', 'created', 'random']
        if (this.elementId) allowedOrders.push('position')
        if (allowedOrders.includes(newQuery.order.toLowerCase())) this.order = newQuery.order.toLowerCase() as ElementOrdering
        else this.order = this.elementId ? 'position' : 'name'
        delete newQuery.order
      } else {
        this.order = this.elementId ? 'position' : 'name'
      }

      if (this.order !== 'random' && newQuery.order_direction) {
        if (['asc', 'desc'].includes(newQuery.order_direction.toLowerCase())) this.orderDirection = newQuery.order_direction as OrderDirection
        else this.orderDirection = 'asc'
        delete newQuery.order_direction
      } else this.orderDirection = 'asc'

      this.selectedFilters = newQuery
    }
  },
  watch: {
    navigationPageSize (newValue: number, oldValue: number) {
      /*
       * When the page size changes, recompute the page number to make sure the first element in the page
       * is still the same.
       * We do not update when the page number is 1, since it will stay at 1 anyway, and we do not update
       * when the page size was `null` in the store, as this means the page size is being filled in for
       * the first time after loading the page.
       * We do not trigger onFilterUpdate either when the page size was `null`, as it is being set by
       * the query watcher which will call onFilterUpdate itself after performing other operations.
       */
      if (oldValue === null) return
      if (newValue !== oldValue && this.pageNumber !== 1) {
        this.pageNumber = Math.ceil((this.pageNumber - 1) * oldValue / newValue)
      } else this.onFilterUpdate()
    },
    pageNumber: 'onFilterUpdate',
    order: 'onFilterUpdate',
    orderDirection: 'onFilterUpdate',
    // Watch when an element is created or deleted in order to quietly reload the navigation elements list
    elementsUpdate: 'silentReload',
    /**
     * When the user enables the classes display, only reload the list when the elements do not currently have prefetched classes
     */
    displayElementClasses (newValue: boolean) {
      if (newValue && !this.navigationStore.visibleElements?.results.some(element => Array.isArray(this.classifications[element.id]))) this.silentReload()
    },
    corpusId: {
      handler: 'updateIds',
      immediate: true
    },
    elementId: {
      handler: 'updateIds',
      immediate: true
    },
    // We cannot use the normal vue-router navigation guards because those only work on the main component of a route
    query: {
      immediate: true,
      handler (query: LocationQuery) {
        this.parseQuery(query)
        this.onFilterUpdate()
      }
    }
  }
})
