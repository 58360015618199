import axios from 'axios'
import { CursorPaginationParameters, PageNumberPaginationParameters, unique } from '.'
import { CursorPagination, PageNumberPagination, UUID } from '@/types'
import { Dataset, DatasetElementList, ElementDatasetSet } from '@/types/dataset'

export interface DatasetListParameters extends CursorPaginationParameters { set?: string | null }

export type DatasetEdit = Partial<Pick<Dataset, 'id' | 'name' | 'description' | 'unique_elements' | 'state'>>

export interface DatasetCreate extends Pick<Dataset, 'name' | 'description' | 'unique_elements'> {
  set_names?: string[]
}

export interface DatasetPopulate {
  parent_id?: UUID
  recursive?: boolean
  types?: string[]
  count?: number
  sets?: Record<string, number>
}

export const listCorpusDataset = unique(
  async (corpusId: UUID, params: PageNumberPaginationParameters = {}): Promise<PageNumberPagination<Dataset>> =>
    (await axios.get(`/corpus/${corpusId}/datasets/`, { params })).data
)

export const createDataset = unique(
  async (corpusId: UUID, data: DatasetCreate): Promise<Dataset> =>
    (await axios.post(`/corpus/${corpusId}/datasets/`, data)).data
)

export const updateDataset = unique(
  async ({ id, ...data }: DatasetEdit): Promise<Dataset> =>
    (await axios.patch(`/datasets/${id}/`, data)).data
)

export const retrieveDataset = unique(
  async (id: UUID): Promise<Dataset> =>
    ((await axios.get(`/datasets/${id}/`)).data)
)

export const deleteDataset = unique(
  async (id: UUID) => await axios.delete(`/datasets/${id}/`)
)

export interface ElementSetListParameters extends PageNumberPaginationParameters {
  with_neighbors?: boolean
}

// List datasets containing a specific element
export const listElementDatasetSets = unique(
  async (elementId: UUID, params: ElementSetListParameters = {}): Promise<PageNumberPagination<ElementDatasetSet>> =>
    (await axios.get(`/element/${elementId}/sets/`, { params })).data
)

export const listDatasetElements = unique(
  async (datasetId: UUID, params: DatasetListParameters = {}): Promise<CursorPagination<DatasetElementList>> =>
    (await axios.get(`datasets/${datasetId}/elements/`, { params })).data
)

export const deleteDatasetElement = unique(
  async (datasetId: UUID, elementId: UUID, set: string) => await axios.delete(`datasets/${datasetId}/elements/${elementId}/`, { params: { set } })
)

export const cloneDataset = unique(
  async (datasetId: UUID): Promise<Dataset> => (await axios.post(`datasets/${datasetId}/clone/`)).data
)

export const createDatasetSet = unique(
  async (datasetId: UUID, name: string) => (await axios.post(`datasets/${datasetId}/sets/`, { name })).data
)

export const updateDatasetSet = unique(
  async (datasetId: UUID, setId: UUID, name: string) => (await axios.patch(`datasets/${datasetId}/sets/${setId}/`, { name })).data
)

export const deleteDatasetSet = unique(
  async (datasetId: UUID, setId: UUID) => await axios.delete(`datasets/${datasetId}/sets/${setId}/`)
)

export const populateDataset = async (datasetId: UUID, payload: DatasetPopulate) => await axios.post(`datasets/${datasetId}/populate/`, payload)
