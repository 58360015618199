import { errorParser } from '@/helpers'
import {
  listCorpora,
  CorpusEditPayload,
  createCorpus,
  updateCorpus,
  deleteCorpus,
  createType,
  updateType,
  deleteType

} from '@/api'
import { useJobsStore, useNotificationStore } from '@/stores'
import { defineStore } from 'pinia'
import { UUID, APICorpus, Corpus, ElementType } from '@/types'
import { isAxiosError } from 'axios'
import { isEmpty } from 'lodash'

interface State {
  corpora: {
    [corpusId: UUID]: Corpus
  }
  loaded: boolean
}

const parseTypes = (typeList: ElementType[]): { [typeId: UUID]: ElementType } => {
  return typeList.reduce((types: { [typeId: UUID]: ElementType }, type: ElementType) => {
    types[type.slug] = type
    return types
  }, {})
}

export const useCorporaStore = defineStore('corpora', {
  state: (): State => {
    return {
      corpora: {},
      loaded: false
    }
  },
  actions: {
    async list () {
      if (this.loaded) return
      try {
        const resp = await listCorpora()
        this.corpora = Object.fromEntries(resp.map((apiCorpus: APICorpus) => [apiCorpus.id, {
          ...apiCorpus,
          types: parseTypes(apiCorpus.types)
        }]))
        this.loaded = true
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        if (isAxiosError(err)) throw err
      }
    },
    async get (corpusId: UUID) : Promise<Corpus> {
      if (isEmpty(this.corpora) || !this.loaded) await this.list()
      if (!this.corpora[corpusId]) throw new Error(`Project ${corpusId} not found.`)
      return this.corpora[corpusId]
    },
    async create (corpusPayload: CorpusEditPayload) {
      try {
        const resp = await createCorpus(corpusPayload)
        const corpus: Corpus = {
          ...resp,
          types: parseTypes(resp.types)
        }
        this.corpora[corpus.id] = corpus
        return corpus
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },
    async update (corpusId: UUID, corpus: CorpusEditPayload) {
      if (!this.corpora[corpusId]) throw new Error(`Project ${corpusId} not found.`)
      try {
        const resp: APICorpus = await updateCorpus(corpusId, corpus)
        this.corpora[corpusId] = {
          ...resp,
          types: parseTypes(resp.types)
        }
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },
    async delete (corpusId: UUID) {
      if (!this.corpora[corpusId]) throw new Error(`Project ${corpusId} not found.`)
      try {
        await deleteCorpus(corpusId)
        delete this.corpora[corpusId]
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        if (isAxiosError(err)) throw err
      } finally {
        await useJobsStore().list()
      }
    },
    async createElementType (corpusId: UUID, type: Omit<ElementType, 'id'>) {
      try {
        const resp = await createType({ corpus: corpusId, ...type })
        this.corpora[corpusId].types[resp.slug] = resp
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        if (isAxiosError(err)) throw err
      }
    },
    async updateElementType (corpusId: UUID, type: ElementType) {
      if (!this.corpora[corpusId]) throw new Error(`Project ${corpusId} not found.`)
      const existingType = Object.values(this.corpora[corpusId].types).find(({ id }: ElementType) => id === type.id)
      if (!existingType) throw new Error(`Type ${type.id} not found in project ${corpusId}`)
      try {
        const resp = await updateType(type)
        delete this.corpora[corpusId].types[existingType.slug]
        this.corpora[corpusId].types[resp.slug] = resp
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        if (isAxiosError(err)) throw err
      }
    },
    async deleteElementType (corpusId: UUID, typeSlug: string) {
      if (!this.corpora[corpusId]) throw new Error(`Project ${corpusId} not found.`)
      if (!this.corpora[corpusId].types[typeSlug]) throw new Error(`Type ${typeSlug} not found in project ${corpusId}`)
      try {
        await deleteType(this.corpora[corpusId].types[typeSlug].id)
        delete this.corpora[corpusId].types[typeSlug]
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        if (isAxiosError(err)) throw err
      }
    }
  }
})
