
import { isAxiosError } from 'axios'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

import { errorParser } from '@/helpers'
import { useAuthStore } from '@/stores'

export default defineComponent({
  props: {
    uidb64: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data: () => ({
    password: '',
    confirmPassword: '',
    loading: false,
    success: false,
    fieldErrors: {} as Record<string, string | string[] | undefined>
  }),
  methods: {
    ...mapActions(useAuthStore, ['resetPassword']),
    setErrors (error: unknown) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (!isAxiosError(error) || !error.response || typeof error.response.data !== 'object') this.fieldErrors = { error: errorParser(error) }
      else this.fieldErrors = error.response.data
    },
    async submit () {
      if (!this.checkPasswords()) return

      this.success = false
      this.loading = true
      this.setErrors(null)
      try {
        await this.resetPassword({
          uidb64: this.uidb64,
          token: this.token,
          password: this.confirmPassword
        })
        this.success = true
      } catch (err) {
        this.setErrors(err)
      } finally {
        this.loading = false
        this.password = ''
        this.confirmPassword = ''
      }
    },
    checkPasswords (): boolean {
      if (this.passwordMatch) return true
      this.fieldErrors = { confirmPassword: ['Passwords do not match'] }
      return false
    }
  },
  computed: {
    passwordMatch (): boolean {
      return !!this.password && !!this.confirmPassword && this.password === this.confirmPassword
    }
  }
})
