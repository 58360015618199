import axios from 'axios'
import { defineStore } from 'pinia'
import { createDataFile, destroyDataFile, listDataFiles, updateDataFile } from '@/api'
import { S3FileStatus } from '@/enums'
import { errorParser } from '@/helpers'
import { useNotificationStore } from '@/stores'
import { UUID } from '@/types'
import { DataFile } from '@/types/files'

export interface StoredDataFile extends DataFile {
  /**
   * Progress of a file upload between 0 and 1. When null, an indefinite progress bar should be shown.
   */
  progress?: number | null
}

interface State {
  files: { [id: UUID]: StoredDataFile }
  selectedIds: Set<UUID>
  loaded: boolean
}

export const useFileStore = defineStore('file', {
  state: (): State => ({
    files: {},
    selectedIds: new Set(),
    loaded: false
  }),

  actions: {
    async list (corpusId: UUID) {
      if (this.loaded) this.files = {}
      try {
        let page = 1
        let next, results
        do {
          ({ next, results } = await listDataFiles(corpusId, { page }))
          for (const file of results) {
            this.files[file.id] = file
          }
          if (next) page++
        } while (next)
        this.loaded = true
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },

    /*
     * Upload a DataFile from a local file or a URL.
     * Expects a File instance as `file` or a URL string as `url`.
     */
    async upload (corpus: UUID, { file, url }: { file?: File, url?: undefined } | { url?: string, file?: undefined }) {
      try {
        let blob: Blob, fileName: string
        if (file && url) throw new Error('A DataFile may be uploaded from a file or a URL but not both')
        else if (url) {
          /*
           * When fetching from a URL, we will get a Blob without a filename.
           * Some browsers may not send a filename in Content-Disposition on API requests for Blob instances,
           * so we deduce the true filename from the URL, by getting its path,
           * removing trailing slashes and getting the last portion of the path.
           * http://something.com/a/b/c/d.json/?bla=bleh#anchor → /a/b/c/d.json/ → /a/b/c/d.json → d.json
           */
          fileName = new URL(url).pathname.replace(/\/$/, '').split('/').pop() ?? ''

          let blobResp
          try {
            blobResp = await fetch(url, { mode: 'cors' })
          } catch (err) {
            if (err && typeof err === 'object' && 'message' in err && typeof err.message === 'string' && err.message.includes('NetworkError')) {
              throw new Error(`Remote URL download failed: ${err.message} The remote server might not support or allow downloading with CORS.`)
            }
            throw err
          }

          if (!blobResp.ok) {
            throw new Error('Remote URL download failed: HTTP ' + blobResp.status)
          }

          blob = await blobResp.blob()
        } else if (file) {
          fileName = file.name
          blob = file
        } else {
          throw new Error('Either a local file or a URL must be set to upload a DataFile')
        }

        const datafile = await createDataFile({
          corpus,
          name: fileName,
          content_type: blob.type,
          size: blob.size
        })
        this.files[datafile.id] = {
          ...datafile,
          progress: null
        }

        /*
         * S3 upload
         * Uses an inner try-catch because beyond this point,
         * error handling also has to set the `error` status on the file.
         */
        try {
          await axios.put(datafile.s3_put_url, blob, {
            headers: {
              'Content-Type': blob.type
            },
            withCredentials: false,
            onUploadProgress: event => {
              /*
               * Actual progress may not always be available if Content-Length is not available,
               * or if the browser does not support it; we will set `progress` to null
               * to let the progress bar be visible, but indeterminate ("infinite loading")
               */
              let progress = null
              if (event.total) {
                progress = event.loaded / event.total
              }
              this.files[datafile.id].progress = progress
            }
          })

          // Mark as infinite loading while checking the file
          this.files[datafile.id].progress = null

          // Set the DataFile to checked and auto-select
          await updateDataFile(datafile.id, { status: S3FileStatus.Checked })
          this.files[datafile.id].status = S3FileStatus.Checked
          delete this.files[datafile.id].progress
          this.selectedIds.add(datafile.id)
        } catch (err) {
          this.files[datafile.id].progress = null
          /*
           * Set the DataFile status to error
           * When this is successful, re-throw the Error for the outer try-catch.
           */
          await updateDataFile(datafile.id, { status: S3FileStatus.Error })
          this.files[datafile.id].status = S3FileStatus.Error
          throw err
        } finally {
          delete this.files[datafile.id].progress
        }
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async delete (id: UUID) {
      try {
        await destroyDataFile(id)
        this.selectedIds.delete(id)
        delete this.files[id]
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    }
  },
  getters: {
    selectedFiles (): DataFile[] {
      return [...this.selectedIds].map(id => this.files[id]).filter(file => file)
    }
  }
})
