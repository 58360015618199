export enum S3FileStatus {
  Unchecked = 'unchecked',
  Checked = 'checked',
  Error = 'error'
}

export enum ProcessActivityState {
  Disabled = 'disabled',
  Pending = 'pending',
  Ready = 'ready',
  Error = 'error'
}

export enum WorkerActivityState {
  Queued = 'queued',
  Started = 'started',
  Processed = 'processed',
  Error = 'error'
}

export enum WorkerVersionState {
  Created = 'created',
  Processing = 'processing',
  Available = 'available',
  Error = 'error'
}

export enum FeatureUsage {
  Disabled = 'disabled',
  Supported = 'supported',
  Required = 'required'
}

export enum ModelVersionState {
  Created = 'created',
  Available = 'available',
  Error = 'error'
}
