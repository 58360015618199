import { createRouter, createWebHistory, createWebHashHistory, isNavigationFailure, NavigationFailureType } from 'vue-router'
import { useAuthStore } from '@/stores'
import { ROUTER_MODE, UUID } from '@/config'
import * as Sentry from '@sentry/vue'

import Navigation from '@/views/Navigation'
import ElementPage from '@/views/Element.vue'
import Selection from '@/views/Navigation/Selection.vue'
import Search from '@/views/Search.vue'
import EntityDetails from '@/views/Entity/Details.vue'
import EntityList from '@/views/Entity/List.vue'
import Register from '@/views/Auth/Register.vue'
import Login from '@/views/Auth/Login.vue'
import Logout from '@/views/Auth/Logout.vue'
import PasswordReset from '@/views/Auth/PasswordReset.vue'
import PasswordResetConfirm from '@/views/Auth/PasswordResetConfirm.vue'
import NotFound from '@/views/Errors/NotFound.vue'
import NoBackend from '@/views/Errors/NoBackend.vue'
import UnverifiedEmail from '@/views/Errors/UnverifiedEmail.vue'
import ImageElements from '@/views/ImageElements.vue'

// Logged in users-only components are split into another JS chunk to improve performance
/* eslint-disable no-inline-comments */
const Welcome = () => import(/* webpackChunkName: "users" */ '@/views/Welcome')
const CorpusList = () => import(/* webpackChunkName: "users" */ '@/views/Corpus/List')
const CorpusAwaiter = () => import(/* webpackChunkName: "users" */ '@/views/Corpus/Awaiter.vue')
const CorpusDetails = () => import(/* webpackChunkName: "users" */ '@/views/Corpus/Main')
const ImportFromFiles = () => import(/* webpackChunkName: "users" */ '@/views/Imports/ImportFromFiles')
const ImportFromBucket = () => import(/* webpackChunkName: "users" */ '@/views/Imports/ImportFromBucket')
const ProcessList = () => import(/* webpackChunkName: "users" */ '@/views/Process/List')
const ModelsList = () => import(/* webpackChunkName: "users" */ '@/views/Model/List')
const Model = () => import(/* webpackChunkName: "users" */ '@/views/Model')
const ModelVersion = () => import(/* webpackChunkName: "users" */ '@/views/Model/Version')
const ProcessDetails = () => import(/* webpackChunkName: "users" */ '@/views/Process/Details')
const ProcessDatasets = () => import(/* webpackChunkName: "users" */ '@/views/Process/Datasets')
const ProcessFilter = () => import(/* webpackChunkName: "users" */ '@/views/Process/Filter')
const ProcessConfigure = () => import(/* webpackChunkName: "users" */ '@/views/Process/Configure')
const Dataset = () => import(/* webpackChunkName: "users" */ '@/views/Dataset/Details')
const Workers = () => import(/* webpackChunkName: "users" */ '@/views/Process/Workers/List')
const WorkerManage = () => import(/* webpackChunkName: "users" */ '@/views/Process/Workers/Manage')
const WorkerVersion = () => import(/* webpackChunkName: "users" */ '@/views/Process/Workers/Version')
const WorkerRuns = () => import(/* webpackChunkName: "users" */ '@/views/Process/WorkerRunsList')
const PonosAgents = () => import(/* webpackChunkName: "users" */ '@/views/Process/Agents')
const GroupManage = () => import(/* webpackChunkName: "users" */ '@/views/Group/Manage')
const UserProfile = () => import(/* webpackChunkName: "users" */ '@/views/Auth/Profile')
const UserVerifyEmail = () => import(/* webpackChunkName: "users" */ '@/views/Auth/VerifyEmail')
const ProcessWorkersActivity = () => import(/* webpackChunkName: "users" */ '@/views/Process/WorkersActivity')

/* eslint-enable no-inline-comments */

const routes = [
  {
    path: '/',
    name: 'home',
    component: Welcome,
    props: false
  },
  {
    path: `/browse/:corpusId(${UUID})`,
    name: 'navigation',
    component: Navigation,
    props: true
  },
  {
    path: `/element/:id(${UUID})`,
    name: 'element-details',
    component: ElementPage,
    props: true
  },
  {
    path: `/image/:imageId(${UUID})`,
    name: 'image-elements',
    component: ImageElements,
    meta: {
      requiresLogin: true,
      requiresVerified: true
    },
    props: true
  },
  {
    path: '/elements/selected',
    name: 'elements-selected',
    component: Selection,
    meta: {
      requiresLogin: true,
      requiresVerified: true,
      requiresFeatures: ['selection']
    },
    props: true
  },
  {
    path: `/search/:corpusId(${UUID})?`,
    name: 'search',
    component: Search,
    meta: { requiresFeatures: ['search'] },
    props: true
  },
  {
    path: '/corpus/new',
    name: 'corpus-create',
    component: CorpusDetails,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: `/corpus/:corpusId(${UUID})`,
    name: 'corpus-update',
    component: CorpusDetails,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: `/corpus/:corpusId(${UUID})/wait`,
    name: 'corpus-wait',
    component: CorpusAwaiter,
    meta: {
      requiresLogin: true,
      requiresVerified: true,
      requiresFeatures: ['enterprise']
    },
    props: true
  },
  {
    path: `/corpus/:corpusId(${UUID})/entities`,
    name: 'corpus-entities',
    component: EntityList,
    props: true
  },
  {
    path: `/entity/:id(${UUID})`,
    name: 'entity-details',
    component: EntityDetails,
    props: true
  },
  {
    path: '/process',
    name: 'processes-list',
    component: ProcessList,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: `/process/files/:corpusId(${UUID})/:folderId(${UUID})?`,
    name: 'process-files',
    component: ImportFromFiles,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: `/process/buckets/:corpusId(${UUID})/:folderId(${UUID})?`,
    name: 'process-buckets',
    component: ImportFromBucket,
    meta: { requiresLogin: true, requiresVerified: true, requiresFeatures: ['ingest'] },
    props: true
  },
  {
    path: `/dataset/:datasetId(${UUID})`,
    name: 'dataset-details',
    component: Dataset,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: '/process/workers',
    name: 'workers-list',
    component: Workers,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: `/process/workers/:workerId(${UUID})`,
    name: 'worker-manage',
    component: WorkerManage,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: `/process/worker-version/:versionId(${UUID})`,
    name: 'worker-version',
    component: WorkerVersion,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: '/process/worker-runs',
    name: 'worker-runs',
    component: WorkerRuns,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: '/process/agents',
    name: 'ponos-agents',
    component: PonosAgents,
    meta: { requiresLogin: true, requiresVerified: true, requiresFeatures: ['enterprise'] }
  },
  // URL of the previous template details page, which has been merged with the process status into ProcessDetails
  {
    path: `/process/:id(${UUID})/template-details`,
    redirect: { name: 'process-details' }
  },
  {
    path: `/process/:id(${UUID})/datasets`,
    name: 'process-datasets',
    component: ProcessDatasets,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: `/process/:id(${UUID})/filter`,
    name: 'process-filter',
    component: ProcessFilter,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: `/process/:id(${UUID})/configure`,
    name: 'process-configure',
    component: ProcessConfigure,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  /*
   * When linking to this route, leave selectedRun unset if the process you are linking to
   * already exists and might have any number of runs and you do know which is the last run.
   * If you are redirecting to a process that just got created, set selectedRun to 0 to
   * avoid an extra redirection to the first run.
   */
  {
    path: `/process/:id(${UUID})/:selectedRun(\\d+)?`,
    name: 'process-details',
    component: ProcessDetails,
    meta: { requiresLogin: true },
    props: route => {
      // Cast the selected run number to a Number
      let selectedRun = Number.parseInt(route.params.selectedRun, 10)
      if (Number.isNaN(selectedRun)) selectedRun = -1
      return {
        ...route.params,
        selectedRun
      }
    }
  },
  {
    path: `/process/:processId(${UUID})/workers-activity`,
    name: 'process-workers-activity',
    component: ProcessWorkersActivity,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: '/user/register',
    name: 'register',
    component: Register,
    meta: { requiresFeatures: ['signup'] },
    props: true
  },
  {
    path: '/user/login',
    name: 'login',
    component: Login,
    props: true
  },
  {
    path: '/user/logout',
    name: 'logout',
    component: Logout,
    props: true
  },
  {
    path: '/user/password_reset',
    name: 'password-reset',
    component: PasswordReset,
    props: true
  },
  {
    path: '/user/reset/:uidb64/:token',
    name: 'password-reset-confirm',
    component: PasswordResetConfirm,
    props: true
  },
  {
    path: '/user/profile/',
    name: 'user-profile',
    component: UserProfile,
    meta: { requiresLogin: true, requiresVerified: true }
  },
  {
    path: '/user/verify-email/',
    name: 'user-verify-email',
    component: UserVerifyEmail
  },
  {
    path: `/group/:groupId(${UUID})`,
    name: 'group-manage',
    component: GroupManage,
    meta: {
      requiresLogin: true,
      requiresVerified: true,
      requiresFeatures: ['enterprise']
    },
    props: true
  },
  {
    path: '/browse/',
    name: 'corpus-list',
    component: CorpusList,
    props: false
  },
  {
    path: '/models',
    name: 'models-list',
    component: ModelsList,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: `/model/:modelId(${UUID})`,
    name: 'model',
    component: Model,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: `/model-version/:versionId(${UUID})`,
    name: 'model-version',
    component: ModelVersion,
    meta: { requiresLogin: true, requiresVerified: true },
    props: true
  },
  {
    path: '/errors/unreachable',
    name: 'no-backend',
    component: NoBackend
  },
  {
    path: '/errors/unverified',
    name: 'unverified-email',
    component: UnverifiedEmail
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  }
]

let history
switch (ROUTER_MODE) {
  case 'history':
    history = createWebHistory()
    break
  case 'hash':
    history = createWebHashHistory()
    break
  // The 'abstract' mode exists, but we do not support it because it only causes confusion.
  default:
    throw new Error(`Unsupported router mode '${ROUTER_MODE}'`)
}

const router = createRouter({
  history,
  routes
})

router.beforeEach(async to => {
  // Do not try to fetch stuff from the API when opening an error page
  if (['no-backend'].includes(to.name)) {
    return
  }

  const authStore = useAuthStore()

  // Fetch authentication info if that was not already done
  if (!authStore.hasInfo) {
    try {
      await authStore.get()
    } catch {
      return { name: 'no-backend' }
    }
  }

  if (to.matched.some(record => {
    return Array.isArray(record.meta.requiresFeatures) &&
      record.meta.requiresFeatures.some(feature => !authStore.hasFeature(feature))
  })) {
    // If the route requires specific feature flags and the instance does not have them, redirect to a 404 page
    return { name: 'not-found' }
  } else if (to.matched.some(record => record.meta.requiresLogin) && !authStore.isLoggedOn) {
    // If the route requires authentication and the user is not logged on, redirect to the login page
    return { name: 'login', query: { next: to.fullPath } }
  } else if (to.matched.some(record => record.meta.requiresVerified) && !authStore.isVerified) {
    // If the route requires having a verified email, redirect to the email verification error page
    return { name: 'unverified-email' }
  }
})

const failureParser = (failure, error = 'Generic router failure') => {
  // Returns an explicit message about a route failure
  const fromRoute = failure?.from?.path || '?'
  const toRoute = failure?.to?.path || '?'
  const extra = Object.entries({ ...failure }).reduce((msg, [key, value]) => {
    if (['from', 'to'].includes(key)) return msg
    msg += ` ${key}="${value}"`
    return msg
  }, '')
  return `${error}: from ${fromRoute} to ${toRoute}.${extra}`
}

router.afterEach((to, from, failure) => {
  if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
    /*
     * Aborted navigations occurs when a router guard denies the navigation
     * by explicitly returning false. This is usually an intended behavior.
     */
    // eslint-disable-next-line no-console
    console.warn(failureParser(failure, 'Aborted route'))
  } else if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
    /*
     * Duplicated routes errors are thrown when the new route is the same as the current one.
     * This can cause a lot of spurious errors (e.g. by double-clicking one of the navbar buttons).
     * Such errors are only displayed in the development environment.
     */
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(failureParser(failure, 'Duplicated route'))
    }
  } else if (isNavigationFailure(failure, NavigationFailureType.cancelled)) {
    /*
     * Routes cancellation are considered as unexpected failures.
     * Report such errors in the console and as a Sentry alert.
     */
    const errorMsg = failureParser(failure, 'Cancelled route')
    // eslint-disable-next-line no-console
    console.error(errorMsg)
    Sentry.captureException(errorMsg, failure)
  }
})

export default router
