
import { mapActions, mapStores } from 'pinia'

import { retrieveImage } from '@/api'
import { iiifUri } from '@/helpers'
import { corporaMixin } from '@/mixins'
import { useImageStore, useNotificationStore } from '@/stores'

import ItemId from '@/components/ItemId.vue'
import Paginator from '@/components/Paginator.vue'
import ElementList from '@/components/Navigation/ElementList.vue'
import { defineComponent } from 'vue'
import { Image, Zone } from '@/types'

// Displayed image details retrieved from the backend
export default defineComponent({
  mixins: [
    corporaMixin
  ],
  components: {
    ItemId,
    Paginator,
    ElementList
  },
  props: {
    imageId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    image: null as Image | null,
    loading: false,
    opened: false
  }),
  async mounted () {
    try {
      this.image = (await retrieveImage(this.imageId)).data
    } catch (err) {
      this.notify({ type: 'error', text: 'An error occurred retrieving image details.' })
    }
  },
  computed: {
    ...mapStores(useImageStore),
    corpusId () {
      if (!this.imageStore.elements?.results?.length) return null
      return this.imageStore.elements.results[0].corpus.id
    },
    fakeZone (): Zone | null {
      if (!this.image || !this.hasValidDimensions) return null
      return {
        id: '',
        image: this.image,
        url: this.image.url,
        polygon: []
      }
    },
    hasValidDimensions (): boolean {
      return this.image !== null && this.image.width > 0 && this.image.height > 0
    },
    imageThumbnail (): string | null {
      if (!this.fakeZone) return null
      return iiifUri(this.fakeZone, { height: 200 })
    },
    fullImage (): string | null {
      if (!this.fakeZone) return null
      return iiifUri(this.fakeZone)
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    async load (page: number | null) {
      this.loading = true
      try {
        await this.imageStore.listElements({
          id: this.imageId,
          page: page ?? 1
        })
      } finally {
        this.loading = false
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    // @ts-expect-error `vm` is not understood by TypeScript
    next(vm => vm.load(to.query.page))
  },
  beforeRouteUpdate (to) {
    this.load(typeof to.query.page === 'string' ? Number.parseInt(to.query.page) : null)
  }
})
