
import { defineComponent, PropType } from 'vue'

import { truncateMixin } from '@/mixins'
import { WorkerConfiguration } from '@/types/workerConfiguration'
import { WorkerVersion } from '@/types/worker'

import ItemId from '@/components/ItemId.vue'
import ConfigurationsList from './List.vue'

export default defineComponent({
  props: {
    configuration: {
      type: Object as PropType<WorkerConfiguration>,
      required: true
    },
    workerVersion: {
      type: Object as PropType<WorkerVersion>,
      required: true
    }
  },
  components: {
    ConfigurationsList,
    ItemId
  },
  mixins: [
    truncateMixin
  ],
  data: () => ({
    configurationModal: false
  })
})
