
import { mapState } from 'pinia'
import { iiifUri, boundingBox } from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'
import { useDisplayStore } from '@/stores'
import { CSSProperties, defineComponent, PropType } from 'vue'
import { Element, ElementBase, UUID } from '@/types'

export default defineComponent({
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true
    }
  },
  data: () => ({
    loaded: false,
    imgError: false
  }),
  computed: {
    ...mapState(useDisplayStore, ['iiifWidth']),
    corpusId (): UUID | null {
      return this.element.corpus?.id ?? null
    },
    imageStyle (): CSSProperties {
      if (this.loaded || !this.hasValidDimensions) return {}
      return {
        'padding-bottom': this.zoneSize.height / this.zoneSize.width * 100 + '%'
      }
    },
    zoneSize () {
      if (!this.element.zone) throw new Error('Element has no zone')
      return boundingBox(this.element.zone)
    },
    source (): string | undefined {
      if (this.element.thumbnail_url) return this.element.thumbnail_url
      if (this.element.zone) return iiifUri(this.element.zone, { width: this.iiifWidth(2 / 3) })
      return undefined
    },
    hasValidDimensions (): boolean {
      return this.element.zone !== null && this.element.zone.image.width > 0 && this.element.zone.image.height > 0
    },
    imgStyle (): CSSProperties {
      return { display: this.loaded ? 'block' : 'none' }
    }
  }
})
