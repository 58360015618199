
import { defineComponent, PropType } from 'vue'
import { WorkerRunSummary } from '@/types/process'
import WorkerRunDetails from './WorkerRunDetails.vue'

export default defineComponent({
  components: {
    WorkerRunDetails
  },
  data: () => ({
    workerRunModal: false
  }),
  props: {
    workerRunDetails: {
      type: Object as PropType<WorkerRunSummary>,
      required: true,
      validator: (value: unknown): boolean =>
        typeof value === 'object' &&
        value !== null &&
        'id' in value &&
        typeof value.id === 'string' &&
        'summary' in value &&
        typeof value.summary === 'string'
    },
    multiline: {
      type: Boolean,
      default: false
    },
    iconOnly: {
      type: Boolean,
      default: false
    }
  }
})
