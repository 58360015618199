
import { PropType, defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { uniqueId } from 'lodash'

import { ModelListParameters } from '@/api'
import { errorParser } from '@/helpers'
import { truncateMixin } from '@/mixins'
import { useModelStore, useNotificationStore } from '@/stores'
import { Model } from '@/types/model'

import Paginator from '@/components/Paginator.vue'
import CreateForm from '@/components/Model/EditForm.vue'
import { PageNumberPagination, UUID } from '@/types'
import { UUID_REGEX } from '@/config'

export default defineComponent({
  mixins: [
    truncateMixin
  ],
  components: {
    Paginator,
    CreateForm
  },
  emits: {
    'update:modelValue': (value: UUID) => typeof value === 'string' && (UUID_REGEX.test(value) || value === '')
  },
  props: {
    /**
     * Show archived models (only when listing models outside of a process)
     */
    showArchived: {
      type: Boolean,
      default: false
    },
    compatibleWorkerId: {
      type: String as PropType<UUID | null>,
      default: null
    },
    // A model ID can be passed by a parent to this component
    modelValue: {
      type: String as PropType<UUID | null>,
      default: null
    }
  },
  data: () => ({
    loading: false,
    modelsPage: null as PageNumberPagination<Model> | null,
    page: 1,
    nameFilter: '',
    allModels: false,
    archivedModels: false,
    uid: uniqueId()
  }),
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useModelStore, ['listModels']),
    async updateModelsPage () {
      this.loading = true
      try {
        const payload: ModelListParameters = { page: this.page, archived: this.archivedModels }
        if (this.nameFilter) payload.name = this.nameFilter
        if (this.compatibleWorkerId && !this.allModels) payload.compatible_worker = this.compatibleWorkerId
        this.modelsPage = await this.listModels(payload)
      } catch (err) {
        this.notify({ type: 'error', text: `An error occurred listing models: ${errorParser(err)}` })
      } finally {
        this.loading = false
      }
    },
    filter () {
      // Update models list. Reset page if required
      if (this.page === 1) return this.updateModelsPage()
      this.page = 1
    }
  },
  computed: {
    ...mapState(useModelStore, ['models'])
  },
  watch: {
    page: {
      immediate: true,
      handler: 'updateModelsPage'
    },
    /*
     * When toggling the Show all models toggle or switching between (un)archived models,
     * reset the page number if it is not at page 1, as those actions may change the number of pages.
     * Resetting the page to 1 will trigger updateModelsPage due to the `page` watcher.
     */
    allModels: {
      handler: 'filter'
    },
    archivedModels: {
      immediate: true,
      handler: 'filter'
    }
  }
})
