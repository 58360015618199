
import { defineComponent, PropType } from 'vue'
import { mapActions, mapState, mapStores } from 'pinia'

import { UUID } from '@/types'
import { UUID_REGEX, EXPORT_FORMATS, EXPORT_STATES } from '@/config'
import { ExportFormat, CorpusExport } from '@/types/export'
import { useAuthStore, useElementStore, useExportStore, useNotificationStore, usePonosStore, useProcessStore, useWorkerStore } from '@/stores'
import { CreateExportProcessParams } from '@/api/export'
import { isAxiosError } from 'axios'

import { corporaMixin, truncateMixin } from '@/mixins'
import { ago, errorParser } from '@/helpers'

import Paginator from '@/components/Paginator.vue'
import Modal from './Modal.vue'
import FormFields from './Process/Workers/Configurations/ConfigurationForm/FormFields.vue'
import { cloneDeep, isEmpty } from 'lodash'
import { WorkerVersion } from '@/types/worker'

export default defineComponent({
  components: {
    Modal,
    Paginator,
    FormFields
  },
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      validator: value => (value === null) || (typeof value === 'string' && UUID_REGEX.test(value)),
      required: true
    },
    format: {
      type: String as PropType<ExportFormat>,
      required: true
    },
    selection: {
      type: Boolean,
      default: false
    },
    elementId: {
      type: String as PropType<UUID>,
      validator: value => (value === null) || (typeof value === 'string' && UUID_REGEX.test(value)),
      default: null
    }
  },
  data: () => ({
    opened: false,
    loading: false,
    page: 1,
    source: 'default',
    exportId: null as UUID | null,
    EXPORT_STATES,
    farmId: null as UUID | null,
    configuration: {} as Record<string, unknown>,
    hasConfigurationErrors: false,
    formErrors: {} as Record<keyof CreateExportProcessParams, string[] | undefined>
  }),
  computed: {
    ...mapState(useAuthStore, ['isVerified', 'hasFeature']),
    ...mapState(useElementStore, ['elements']),
    ...mapState(useExportStore, ['corpusExports', 'sources']),
    ...mapState(usePonosStore, ['farms']),
    ...mapStores(useWorkerStore),
    hasAdminPrivilege (): boolean {
      return (this.isVerified && this.corpus && this.canAdmin(this.corpus)) ?? false
    },
    canExport (): boolean {
      return !this.loading && !this.hasConfigurationErrors && this.hasAdminPrivilege && (this.exportId !== undefined) && this.exportWorkerVersion !== null
    },
    element () {
      if (this.elementId) return this.elements[this.elementId] || null
      return null
    },
    exportTarget (): string {
      if (this.selection) return `selected elements from project ${this.truncateShort(this.corpus?.name)}`
      if (this.element) return `${this.truncateShort(this.element.type)} ${this.truncateShort(this.element.name)}`
      return `project ${this.truncateShort(this.corpus?.name)}`
    },
    exportFormatLabel (): string {
      return EXPORT_FORMATS[this.format].label
    },
    exportWorkerVersion (): WorkerVersion | null {
      if (!this.format || !(this.format in EXPORT_FORMATS)) return null
      const id = this.workerStore.featureWorkerVersionIds[EXPORT_FORMATS[this.format].feature]
      return id ? this.workerStore.workerVersions[id] : null
    },
    schema () {
      if (!this.exportWorkerVersion) return null
      const userconfig = this.exportWorkerVersion.configuration?.user_configuration
      if (isEmpty(userconfig)) return null
      // Sort configuration parameters alphabetically by title
      const fields = Object.entries(userconfig)
      fields.sort(function (a, b) {
        const titleA = a[1].title.toLowerCase()
        const titleB = b[1].title.toLowerCase()
        if (titleA < titleB) return -1
        if (titleA > titleB) return 1
        return 0
      })
      const schema = Object.fromEntries(fields)
      // remove the "export_id" configuration entry
      delete schema.export_id
      return schema
    },
    defaultConfiguration () {
      if (!this.schema) return {}
      const filledForm: Record<string, unknown> = {}
      for (const property in this.schema) {
        filledForm[property] = this.schema[property].default ?? ''
      }
      return filledForm
    },
    exportButtonTitle () {
      if (this.hasConfigurationErrors) return 'Please enter a valid configuration'
      if (!this.exportWorkerVersion) return
      return 'Start export'
    }
  },
  methods: {
    ...mapActions(useProcessStore, ['createExportProcess']),
    ...mapActions(useExportStore, ['list', 'listSources', 'start']),
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(usePonosStore, ['listFarms']),
    open () {
      this.opened = true
    },
    dateAgo (date: string): string {
      return ago(new Date(date))
    },
    async load (page = 1) {
      this.loading = true
      try {
        await this.list(this.corpusId, page)
      } finally {
        this.loading = false
      }
    },
    selectTitle (corpusExport: CorpusExport) {
      if (corpusExport.state === 'failed') return 'This export cannot be selected as it has failed'
      return 'Use this export'
    },
    checkConfiguration (value: boolean) {
      this.hasConfigurationErrors = value
    },
    async startExport () {
      const params: CreateExportProcessParams = {
        format: this.format,
        export_id: this.exportId
      }
      if (!isEmpty(this.configuration)) params.configuration = this.configuration
      if (this.hasFeature('enterprise')) params.farm_id = this.farmId
      if (this.elementId) params.element_id = this.elementId
      else if (this.selection) params.selection = true

      try {
        const newProcess = await this.createExportProcess(this.corpusId, params)
        this.$router.push({ name: 'process-details', params: { id: newProcess.id } })
      } catch (err) {
        if (isAxiosError(err) && err.response) {
          this.formErrors = err.response.data
        }
        this.notify({ type: 'error', text: errorParser(err) })
      }
    }
  },
  watch: {
    opened: {
      async handler (newValue) {
        if (newValue) {
          try {
            this.loading = true
            if (this.hasFeature('enterprise') && this.isVerified) {
              this.listSources()
              this.listFarms()
            }
            await this.list(this.corpusId, 1)

            if (!this.format) return
            const feature = EXPORT_FORMATS[this.format].feature
            if (!(feature in this.workerStore.featureWorkerVersionIds)) {
              await this.workerStore.getFeatureWorkerVersion(feature)
            }

            this.configuration = cloneDeep(this.defaultConfiguration)
          } finally {
            this.loading = false
          }
        }
      },
      immediate: true
    },
    page: 'load'
  }
})
