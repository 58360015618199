/*
 * Settings
 * This *must* be the first import because this module sets __webpack_public_path__ for static assets.
 * See https://webpack.js.org/guides/public-path/#on-the-fly
 */
import {
  API_BASE_URL,
  CSRF_COOKIE_NAME,
  CSRF_COOKIE_HEADER,
  CSRF_ALL_ORIGINS,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  UUID,
  VERSION
} from './config'

// Styling
import '@/scss/main.scss'

// Vue js setup
import { createApp } from 'vue'
import { createPinia } from 'pinia'

import router from './router'
import App from '@/views/App.vue'

import axios from 'axios'
import Mousetrap from 'mousetrap'

import * as Sentry from '@sentry/vue'
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'

if (!API_BASE_URL) throw new Error('Missing API_BASE_URL')
axios.defaults.baseURL = API_BASE_URL
axios.defaults.xsrfCookieName = CSRF_COOKIE_NAME
axios.defaults.xsrfHeaderName = CSRF_COOKIE_HEADER
axios.defaults.withCredentials = true
/*
 * `false` means no CSRF token is ever sent in any request,
 * `undefined` means the CSRF token is only sent to the same origin (default),
 * `true` means the token is sent to everyone.
 * Dev builds will need `true`, since devs will need to reach localhost:8000 from :8080.
 */
axios.defaults.withXSRFToken = CSRF_ALL_ORIGINS ? true : undefined

// Try to ensure we do not get anything other than JSON…
axios.defaults.headers.Accept = 'application/json'
/*
 * …Except that will not be enough. Django REST Framework will cause HTTP 406, but many servers ignore the Accept header.
 * If we wanted JSON and got something else, log a warning.
 * If we got text/html, it might be caused by a catch-all URL serving the frontend itself: a 404 in disguise.
 */
axios.interceptors.response.use(response => {
  const requestType = response.config.headers?.Accept
  const responseType = response.headers['content-type']
  if (typeof requestType === 'string' && typeof responseType === 'string' && requestType.startsWith('application/json') && !responseType.startsWith('application/json')) {
    // eslint-disable-next-line no-console
    console.warn(`Unexpected Content-Type from ${response.config.url}: Requested ${requestType} but got ${responseType}`)

    if (responseType.startsWith('text/html') && typeof response.data === 'string') {
      return Promise.reject(new axios.AxiosError(
        'Not found.',
        axios.AxiosError.ERR_BAD_REQUEST,
        response.config,
        response.request,
        response
      ))
    }
  }
  return response
}, async error => {
  /*
   * Do not try to send an error to Sentry when:
   * - It is an HTTP 403 or 404 error
   * - It is an Axios "Network Error"
   *   https://github.com/axios/axios/blob/16aa2ce7fa42e7c46407b78966b7521d8e588a72/lib/adapters/xhr.js#L91
   * - It is an Axios "Request aborted" error
   *   https://github.com/axios/axios/blob/16aa2ce7fa42e7c46407b78966b7521d8e588a72/lib/adapters/xhr.js#L103
   */
  if (
    error.message === 'Network Error' ||
    (error.message === 'Request aborted' && error.code === 'ECONNABORTED') ||
    (error.response && [403, 404].includes(error.response.status))
  ) return await Promise.reject(error)

  // Get the request path, without the host
  let path: string | null = null
  try {
    path = error.request ? new URL(error.request.responseURL).pathname : null
  } catch (e) {
    // Can't do much here, responseURL may be null on some Network errors with requests
  }
  // Remove UUIDs to get the original API endpoint paths
  if (path) path = path.replace(new RegExp(UUID), '<uuid>')

  let fingerprint: string[] = []
  if (error.response) {
    /*
     * Error with a response from the backend
     * Fingerprint has most data, with response status, method, and data
     */
    fingerprint = [
      'axios-error-response',
      error.response.status,
      error.response.config.method,
      path,
      JSON.stringify(error.response.data)
    ]
  } else if (error.request) {
    // Error without any response
    fingerprint = ['axios-error-request', path ?? '']
  } else {
    // Error occurring before sending the request
    fingerprint = ['axios-error-setup', error]
  }

  /*
   * Report issue on Sentry with that fingerprint
   * to group relevant axios issues together
   */
  Sentry.withScope(scope => {
    scope.setFingerprint(fingerprint)

    // path can be null for network errors
    if (path) scope.setTag('api-path', path)
    if (error.response) {
      scope.setTag('method', error.response.config.method)
      scope.setContext('response', {
        data: JSON.stringify(error.response.data, null, 2)
      })
    }

    Sentry.captureException(error)
  })

  return await Promise.reject(error)
})

const app = createApp(App)

app.use(createPinia())
app.use(router)

// Sentry setup
if (SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    logErrors: true,
    integrations: [
      /*
       * Call toJSON or toString on all non-native attributes of Error objects, and send them through in Sentry events.
       * This can be very useful to troubleshoot HTTP errors, among others.
       */
      new ExtraErrorDataIntegration()
    ],
    ignoreErrors: [
      /*
       * When a user clicks on a link to Arkindex from Microsoft Outlook or Teams, if their sysadmin has enabled
       * the "Safe Links" feature, the frontend gets loaded in a strange sandbox that causes hundreds of unhandled
       * promise rejections.
       * https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/7
       */
      'Non-Error promise rejection captured with value: Object Not Found Matching Id'
    ],
    release: `arkindex-frontend@${VERSION}`
  })
}

/*
 * Overwrite the Mousetrap.stopCallback function, which filters keyboard events to determine if they should be applied.
 * The default implementation rejects all events for <input>, <select>, <textarea> and any element with `contenteditable`,
 * unless they have the `mousetrap` CSS class.
 * We now allow the Esc key anywhere within a Bulma modal, and allow both Esc and Enter on every element except textareas.
 * Original implementation: https://github.com/ccampbell/mousetrap/blob/2f9a476ba6158ba69763e4fcf914966cc72ef433/mousetrap.js#L973
 */
Mousetrap.prototype.stopCallback = (e: Mousetrap.ExtendedKeyboardEvent, element: Element, combo: string): boolean => {
  // We only need to apply special handling for <input>, <select>, <textarea>, and any element with the `contenteditable` HTML attribute.
  if (
    !['INPUT', 'SELECT', 'TEXTAREA'].includes(element.tagName) && (
      !(element instanceof HTMLElement) ||
      element.contentEditable !== 'true'
    )
  ) return false

  // Allow the `mousetrap` CSS class to force all events to be accepted, just like the original implementation
  if (element.classList.contains('mousetrap')) return false

  // Detect whether any parent element is a modal
  if (element.matches(`.modal ${element.tagName}`)) {
    // In a textarea, ignore all events except the Esc key
    if (element.tagName === 'TEXTAREA') return combo !== 'esc'
    // Outside a textarea, ignore all events except Esc and Enter
    else return !['esc', 'enter'].includes(combo)
  }

  // We are not in modal, but we are in a form field, ignore everything
  return true
}

app.mount('#app')
