
import { isEmpty } from 'lodash'
import { mapState, mapActions } from 'pinia'
import { defineComponent, PropType } from 'vue'

import { corporaMixin, truncateMixin } from '@/mixins'
import { errorParser } from '@/helpers'
import { useAuthStore, useDatasetStore, useNotificationStore } from '@/stores'

import Modal from '@/components/Modal.vue'
import DatasetSet from '@/components/Corpus/Datasets/DatasetSets/Row.vue'
import AddSetForm from '@/components/Corpus/Datasets/DatasetSets/CreateForm.vue'
import { UUID } from '@/types'
import { Dataset } from '@/types/dataset'
import { DatasetCreate } from '@/api'
import { isAxiosError } from 'axios'

export default defineComponent({
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  components: {
    Modal,
    DatasetSet,
    AddSetForm
  },
  emits: ['dataset-action', 'update:modelValue'],
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    datasetInstance: {
      type: Object as PropType<Dataset | null>,
      default: null
    }
  },
  data: () => ({
    newDataset: {
      name: '',
      description: '',
      set_names: [],
      unique_elements: true
    } as DatasetCreate,
    loading: false,
    fieldErrors: {} as Partial<Record<keyof DatasetCreate, string[]>>
  }),
  mounted () {
    if (this.datasetInstance) {
      this.newDataset = {
        name: this.datasetInstance.name,
        description: this.datasetInstance.description,
        set_names: this.datasetInstance.sets.map(set => set.name),
        unique_elements: this.datasetInstance.unique_elements
      }
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isVerified']),
    hasContribPrivilege () {
      return this.isVerified && this.corpus && this.canWrite(this.corpus)
    },
    modalTitle () {
      if (!this.datasetInstance) return 'Create a new dataset'
      else return `Edit dataset ${this.truncateLong(this.datasetInstance.name)}`
    },
    canSave () {
      return this.newDataset.name.trim() && this.newDataset.description.trim()
    },
    saveButtonTitle () {
      if (!this.canSave) return 'Name and description fields cannot be empty'
      else if (this.datasetInstance) return 'Save'
      else return 'Create'
    }
  },
  methods: {
    ...mapActions(useDatasetStore, ['createCorpusDataset', 'updateCorpusDataset']),
    ...mapActions(useNotificationStore, ['notify']),
    async save () {
      if (!this.hasContribPrivilege || this.loading || !this.canSave) return
      this.loading = true
      const data: DatasetCreate = {
        name: this.newDataset.name.trim(),
        description: this.newDataset.description.trim(),
        unique_elements: this.newDataset.unique_elements
      }
      if (!this.datasetInstance) {
        // Add list of set names to the payload when creating a new dataset
        const setList = this.newDataset.set_names?.map(item => item.trim()).filter(item => item.length > 0)
        if (!isEmpty(setList)) data.set_names = setList
      }

      let instanceId
      try {
        if (this.datasetInstance) {
          instanceId = this.datasetInstance.id
          await this.updateCorpusDataset(
            {
              id: instanceId,
              ...data
            }
          )
        } else {
          const resp = await this.createCorpusDataset(this.corpusId, data)
          instanceId = resp.id
        }
        this.loading = false
        // Sending a custom event to let the parent know that it must reload the list of datasets
        this.$emit('dataset-action', instanceId)
        // Close the modal
        this.$emit('update:modelValue', false)
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 400 && e.response.data) {
          this.fieldErrors = this.parseFieldErrors(e.response.data)
        } else this.notify({ type: 'error', text: errorParser(e) })
      } finally {
        this.loading = false
      }
    },
    parseFieldErrors (errors: unknown): Record<string, string> {
      if (!errors || typeof errors !== 'object' || Array.isArray(errors)) return { detail: errorParser(errors) }
      return Object.fromEntries(
        Object
          .entries(errors)
          .map(([key, value]) => [key, errorParser(value)])
      )
    },
    addSetField () {
      if (this.newDataset.set_names) this.newDataset.set_names.push('')
      else this.newDataset.set_names = ['']
    },
    updateSetNames (i: number, newValue: string) {
      if (!this.newDataset.set_names || this.newDataset.set_names[i] === newValue) return
      // Do not keep the last valid value in the list if the field is emptied
      if (!String(newValue).length) {
        (this.newDataset.set_names.splice(i, 1, newValue))
      }
      this.newDataset.set_names.splice(i, 1, newValue)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler () {
        if (this.datasetInstance) {
          this.newDataset = {
            name: this.datasetInstance.name,
            description: this.datasetInstance.description,
            set_names: this.datasetInstance.sets.map(set => set.name),
            unique_elements: this.datasetInstance.unique_elements
          }
        } else {
          this.newDataset = {
            name: '',
            description: '',
            set_names: ['train', 'dev', 'test'],
            unique_elements: true
          }
        }
        this.fieldErrors = {}
      }
    }
  }
})
