
import { mapState, mapActions } from 'pinia'
import { defineComponent } from 'vue'

import { useAuthStore, useDisplayStore, useNotificationStore } from '@/stores'
import { VERSION, BANNER_MESSAGE, BANNER_STYLE } from '@/config'

import Navbar from '@/components/Navbar.vue'
import Notification from '@/components/Notification.vue'

export default defineComponent({
  components: {
    Navbar,
    Notification
  },
  data: () => ({
    VERSION,
    loggingWarning: false,
    passwordWarning: false,
    emailWarning: false
  }),
  mounted () {
    this.setScreenSize()
    if (BANNER_MESSAGE) {
      this.notify({ type: BANNER_STYLE, text: BANNER_MESSAGE, timeout: 0, markdown: true })
    }
  },
  computed: {
    ...mapState(useAuthStore, ['user', 'isLoggedOn', 'isVerified', 'hasFeature']),
    ...mapState(useNotificationStore, ['notifications']),
    noLoginPage (): boolean {
      if (!this.$route.name) return true
      // Prevent displaying the notification to specific pages
      return ['home', 'login', 'register'].includes(this.$route.name.toString())
    },
    releaseNotes (): string | undefined {
      if (!VERSION) return
      const matches = VERSION.match(/\d+\.\d+\.\d+/g)
      if (!matches) return
      const versionNumber = matches[0].replace(/\./g, '')
      return `https://teklia.com/our-solutions/arkindex/releases/arkindex-release-${versionNumber}/`
    }
  },
  methods: {
    ...mapActions(useAuthStore, ['sendVerificationEmail']),
    ...mapActions(useDisplayStore, ['setScreenSize']),
    ...mapActions(useNotificationStore, ['notify']),
    checkWarnings () {
      const user = this.user
      const routeName = this.$route.name
      if (!user || !routeName) return
      this.emailWarning = !this.isVerified && routeName !== 'user-verify-email'
      // Avoid confusion as profile page is only accessible to verified emails
      this.passwordWarning = !this.emailWarning && !user.has_usable_password
    }
  },
  watch: {
    isLoggedOn: {
      immediate: true,
      handler (loggedOn: boolean) {
        this.loggingWarning = !loggedOn
      }
    },
    user: {
      immediate: true,
      handler: 'checkWarnings'
    },
    $route: {
      immediate: true,
      handler: 'checkWarnings'
    }
  }
})
