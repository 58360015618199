import { getActivePinia, StoreGeneric } from 'pinia'

export function resetStores (exclude: string[] = []) {
  const pinia = getActivePinia()
  if (!pinia) throw new Error('No active Pinia instance')
  if (!('_s' in pinia)) throw new Error('No Pinia store registry found')
  for (const [id, store] of pinia._s as Map<string, StoreGeneric>) {
    if (exclude.includes(id)) continue
    store.$reset()
  }
}

export { useAllowedMetaDataStore } from './allowedMetadata'
export { useAnnotationStore } from './annotation'
export { useAuthStore } from './auth'
export { useClassificationStore } from './classification'
export { useCorporaStore } from './corpora'
export { useDatasetStore } from './dataset'
export { useDisplayStore } from './display'
export { useElementStore } from './element'
export { useEntityStore } from './entity'
export { useEntityTypesStore } from './entityTypes'
export { useExportStore } from './exports'
export { useFileStore } from './files'
export { useFolderPickerStore } from './folderpicker'
export { useImageStore } from './image'
export { useIngestStore } from './ingest'
export { useJobsStore } from './jobs'
export { useMetaDataStore } from './metadata'
export { useMLResultsStore } from './mlresults'
export { useModelStore } from './model'
export { useNavigationStore } from './navigation'
export { useNotificationStore } from './notification'
export { usePonosStore } from './ponos'
export { useProcessStore } from './process'
export { useRightsStore } from './rights'
export { useSearchStore } from './search'
export { useSelectionStore } from './selection'
export { useTranscriptionStore } from './transcription'
export { useTreeStore } from './tree'
export { useWorkerStore } from './workers'
