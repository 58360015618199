
import { isAxiosError } from 'axios'
import { mapState, mapActions } from 'pinia'
import { groupBy, orderBy } from 'lodash'
import { CSSProperties, defineComponent, PropType } from 'vue'

import { TEXT_ORIENTATIONS } from '@/config'
import {
  errorParser,
  getSize,
  boundingBox,
  mirrorX,
  rotateAround,
  orientationStyle,
  hasWorkerRun
} from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'
import {
  useNotificationStore,
  useTranscriptionStore,
  useWorkerStore,
  useAnnotationStore
} from '@/stores'
import {
  Element,
  ElementBase,
  Polygon,
  Point,
  TextOrientation,
  UUID
} from '@/types'
import { WorkerRunSummary as WorkerRunSummaryType } from '@/types/process'

import ElementImage from '@/components/Image/ElementImage.vue'
import Modal from '@/components/Modal.vue'
import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import EditableTranscription from './EditableTranscription.vue'

export default defineComponent({
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    ElementImage,
    Modal,
    EditableTranscription,
    WorkerRunSummary
  },
  emits: ['update:modal'],
  props: {
    modal: {
      type: Boolean,
      required: true
    },
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true
    }
  },
  data: () => ({
    loading: false,
    text: '',
    // API fields validation errors
    fieldErrors: {} as { text?: string[], orientation?: string[] },
    TEXT_ORIENTATIONS
  }),
  mounted () {
    this.$nextTick(() => {
      (this.$refs.textInput as HTMLInputElement).focus()
    })
  },
  computed: {
    ...mapState(useTranscriptionStore, { eltsTranscriptions: 'transcriptions' }),
    ...mapState(useWorkerStore, ['workerVersions']),
    canCreate () {
      if (this.text.trim().length <= 0) return this.loading || !this.canWrite(this.corpus)
      return !this.loading && this.isValid && !this.canWrite(this.corpus)
    },
    createTitle () {
      if (!this.canWrite(this.corpus)) return 'A write right on the project is required to create a transcription'
      const text = this.text.trim()
      if (!text && !this.orientation) return 'Please fill out the creation form'
      else if (!text) return 'A valid text is required to create the transcription'
      else if (!this.orientation || !(this.orientation in TEXT_ORIENTATIONS)) return 'A valid text orientation is required'
      return 'Create transcription'
    },
    orientation: {
      get () {
        return useAnnotationStore().textOrientation
      },
      set (newValue: TextOrientation) {
        this.setTextOrientation(newValue)
      }
    },
    /**
     * Transcriptions sorted by descending confidence and ascending text.
     * This sorting is computed once and then re-used by other computed properties
     * to perform the grouping.
     */
    sortedTranscriptions () {
      return orderBy(
        this.eltsTranscriptions[this.element.id],
        ['confidence', 'text'],
        ['desc', 'asc']
      )
    },
    manualTranscriptions () {
      return this.sortedTranscriptions.filter(transcription => !transcription.worker_run)
    },
    workerRunTranscriptions () {
      const grouped = groupBy(
        this.sortedTranscriptions.filter(transcription => transcription.worker_run),
        'worker_run.id'
      )
      return orderBy(Object.entries(grouped), ([id]) => this.workerRunSummaries[id])
    },
    /**
     * Worker run summary serializers mapped to their IDs.
     * @returns {
     */
    workerRunSummaries (): { [id: string]: WorkerRunSummaryType } {
      return Object.fromEntries(
        this.sortedTranscriptions
          .filter(hasWorkerRun)
          .map(transcription => [transcription.worker_run.id, transcription.worker_run])
      )
    },
    elementZone () {
      const zone = this.element.zone
      if (!zone || !zone.image || !zone.polygon || zone.image.width <= 0 || zone.image.height <= 0) return null
      return zone
    },
    corpusId (): UUID | null {
      // Corpus ID for corporaMixin
      return this.element.corpus?.id ?? null
    },
    isValid (): boolean {
      return this.text.trim().length > 0 && this.orientation in TEXT_ORIENTATIONS
    },
    isPortrait (): boolean {
      // Return true if the zone is in portrait mode, false otherwise
      if (!this.elementZone) return false
      const bbox = boundingBox(this.elementZone)
      const center: Point = [bbox.x + bbox.width / 2, bbox.y + bbox.height / 2]
      let boundingPolygon: Polygon = [
        [bbox.x, bbox.y],
        [bbox.x + bbox.width, bbox.y],
        [bbox.x, bbox.y + bbox.height],
        [bbox.x + bbox.width, bbox.y + bbox.height]
      ]
      if (this.element.mirrored) boundingPolygon = boundingPolygon.map(point => mirrorX(point, center))
      if (this.element.rotation_angle) boundingPolygon = boundingPolygon.map(point => rotateAround(point, center, this.element.rotation_angle))
      const [width, height] = getSize(boundingPolygon)
      return height > width
    },
    isLarge () {
      return this.elementZone !== null && boundingBox(this.elementZone).width > 500
    },
    imageStyle (): CSSProperties {
      const style: CSSProperties = { margin: 'auto' }
      if (this.isPortrait) {
        style['max-height'] = '75vh'
      } else {
        style['max-height'] = '50vh'
        style['max-width'] = '75vw'
      }
      return style
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useAnnotationStore, ['setTextOrientation']),
    ...mapActions(useTranscriptionStore, ['create', 'list']),
    orientationStyle,
    async load () {
      if (this.eltsTranscriptions[this.element.id] === undefined) {
      // Load element transcriptions if necessary
        await this.list(this.element.id)
      }
    },
    setErrors (error: unknown) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (isAxiosError(error) && typeof error.response?.data === 'object') this.fieldErrors = error.response.data
    },
    async createTranscription () {
      if (!this.isValid) return
      if (!this.canWrite(this.corpus)) {
        return this.notify({ type: 'error', text: 'A write right on the project is required to create a transcription' })
      }
      try {
        this.loading = true
        await this.create(
          this.element.id,
          {
            text: this.text,
            orientation: this.orientation
          }
        )
        this.notify({ type: 'success', text: 'Transcription created.' })
        // Close modal in case of success
        this.$emit('update:modal', false)
      } catch (e) {
        this.setErrors(e)
        this.notify({ type: 'error', text: `An error occurred during transcription creation: ${errorParser(e)}` })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    element: {
      immediate: true,
      handler: 'load'
    }
  }
})
