import { defineStore } from 'pinia'
import {
  createElement,
  createParentElement,
  deleteElement,
  ElementChildrenListParameters,
  ElementCreate,
  ElementNeighbor,
  ElementParentListParameters,
  ElementSetListParameters,
  ElementUpdate,
  listElementChildren,
  listElementDatasetSets,
  listElementNeighbors,
  listElementParents,
  moveElement,
  retrieveElement,
  updateElement
} from '@/api'
import { errorParser } from '@/helpers'
import { CorpusLight, Element, ElementBase, PageNumberPagination, UUID } from '@/types'
import { ElementDatasetSet } from '@/types/dataset'
import {
  useAnnotationStore,
  useClassificationStore,
  useJobsStore,
  useNotificationStore
} from '@/stores'
import { ELEMENT_LIST_MAX_AUTO_PAGES } from '@/config'
import { isAxiosError } from 'axios'
import { merge, zip } from 'lodash'
import { useNavigationStore } from './navigation'

interface ElementChildrenPagination extends Omit<PageNumberPagination<unknown>, 'results'> {
  /**
   * Date at which the last page was retrieved, using RFC 2822 section 3.3 format: `Wed, 28 Jul 1993 12:39:07 GMT`.
   *
   * This can be used as the value of a `If-Modified-Since` header.
   */
  retrievedOn?: string
}

interface State {
  elements: { [id: UUID]: ElementBase | Element }

  /**
   * A list of ancestor IDs fetched recursively for a given element.
   */
  parents: { [elementId: UUID]: UUID[] }

  neighbors: { [elementId: UUID]: ElementNeighbor[] }

  links: { [elementId: UUID]: { parents: UUID[], children: UUID[] } }

  childrenPagination: { [elementId: UUID]: ElementChildrenPagination }

  /**
   * Boolean toggled when an element is created or deleted.
   * This is used by the navigation in order to update the elements list and count in case of an
   * action triggered from the tree or the navigation stores.
   */
  elementsUpdate: boolean

  /**
   * List of datasets that contains a specific element
   */
  elementDatasetSets: { [elementId: UUID]: ElementDatasetSet[] }
}

export const useElementStore = defineStore('element', {
  state: (): State => ({
    elements: {},
    parents: {},
    neighbors: {},
    links: {},
    childrenPagination: {},
    elementsUpdate: false,
    elementDatasetSets: {}
  }),
  actions: {
    set (element: Element | ElementBase) {
      this.elements[element.id] = merge(this.elements[element.id] ?? {}, element)
    },

    bulkSet (elements: (Element | ElementBase)[]) {
      this.elements = {
        ...this.elements,
        ...Object.fromEntries(elements.map(
          element => [element.id, merge(this.elements[element.id] ?? {}, element)]
        ))
      }
      const newLinks = Object.fromEntries(
        elements
          .filter(({ id }) => !this.links[id])
          .map(({ id }) => [id, { parents: [], children: [] }])
      )
      if (newLinks) this.links = { ...this.links, ...newLinks }
    },

    /**
     * Entirely remove an element and its paths from the store
     */
    remove (id: UUID) {
      delete this.elements[id]
      // Delete element own path and its reference in other paths
      const newLinks = { ...this.links }
      delete newLinks[id]
      // Delete element from other paths
      Object.values(newLinks).map(({ parents, children }) => [parents, children]).flat(1).forEach(elts => {
        const index = elts.findIndex(e => e === id)
        if (index >= 0) elts.splice(index, 1)
      })
      this.links = newLinks
    },

    /**
     * Add children to an element. This both adds the children elements to `elements` and the IDs to `links`.
     * Expects an element ID and a list of children elements.
     */
    addChildren (parentId: UUID, children: ElementBase[] = []) {
      if (!children?.length) return
      this.bulkSet(children)

      // Add the children IDs to the parent's links
      const parentLink = this.links[parentId] ?? { parents: [], children: [] }
      parentLink.children.push(...children.map(({ id }) => id).filter(id => !parentLink.children.includes(id)))
      this.links[parentId] = parentLink

      // Add the parent ID to the child links
      for (const child of children) {
        // Add parentId to child's parents
        if (!this.links[child.id].parents.includes(parentId)) this.links[child.id].parents.push(parentId)
      }
    },

    /**
     * Add parents to an element. This does not update links because the parents can be listed recursively.
     */
    addParents (element: UUID, parents: ElementBase[] = [], corpus: CorpusLight) {
      if (!corpus) throw new Error('A corpus is required.')
      if (!parents) return
      // Holds all of the updated parents to be set in this.parents
      const newParents = this.parents[element] ?? []
      for (const parent of parents) {
        this.elements[parent.id] = merge(parent, this.elements[parent.id] ?? {}, { corpus })
        // Add parent to element's parents
        if (!newParents.includes(parent.id)) newParents.push(parent.id)
      }
      // Apply updated parents to the state
      this.parents[element] = newParents
    },

    /**
     * Remove all children links from an element, for cache invalidation.
     */
    resetChildren (id: UUID) {
      if (!this.links[id]?.children?.length) return

      // Remove children links from the parent
      const newLinks: typeof this.links = {
        [id]: {
          ...this.links[id],
          children: []
        }
      }

      // Remove the parent element from its children's parent links
      for (const childId of this.links[id].children) {
        const parents = this.links[childId]?.parents
        if (!parents?.length) continue

        const parentIndex = parents.indexOf(id)
        if (parentIndex < 0) continue

        newLinks[childId] = {
          ...this.links[childId],
          parents: [...parents]
        }
        newLinks[childId].parents.splice(parentIndex, 1)
      }

      // Apply changes to the state
      this.links = { ...this.links, ...newLinks }
    },

    async get (id: UUID): Promise<Element> {
      try {
        const element = useClassificationStore().cleanElement(await retrieveElement(id))
        // If we just retrieved the currently selected element, update its metadata
        const annotationStore = useAnnotationStore()
        if (element.id === annotationStore.selectedElement?.id) {
          annotationStore.selectedElement = element
        }
        this.set(element)
        return element
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async create (payload: ElementCreate) {
      try {
        // Element creation endpoint returns a fully serialized element
        const element = useClassificationStore().cleanElement(await createElement(payload))
        this.set(element)
        // If we just retrieved the currently selected element, update its metadata
        const annotationStore = useAnnotationStore()
        if (element.id === annotationStore.selectedElement?.id) {
          annotationStore.selectedElement = element
        }

        // Add parent link for this element
        if (payload.parent) this.addChildren(payload.parent, [element])
        else this.links[element.id] = { parents: [], children: [] }

        // Toggle elementsUpdate boolean for navigation
        this.elementsUpdate = !this.elementsUpdate

        return element
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async patch (id: UUID, payload: ElementUpdate) {
      try {
        const element = await updateElement(id, payload)
        this.set(element)
        // If we just retrieved the currently selected element, update its metadata
        const annotationStore = useAnnotationStore()
        if (annotationStore.selectedElement?.id === element.id) {
          annotationStore.selectedElement = element
        }
        // Toggle elementsUpdate boolean for navigation e.g. a name update
        this.elementsUpdate = !this.elementsUpdate
        return element
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },

    async delete (id: UUID, recursive = true) {
      try {
        await deleteElement(id, { delete_children: recursive })
        this.remove(id)
        const annotationStore = useAnnotationStore()
        if (annotationStore.selectedElement?.id === id) {
          annotationStore.selectedElement = null
        }
        useNavigationStore().scheduledDeletion.add(id)
        useNotificationStore().notify({ type: 'success', text: 'Element deletion has been scheduled.' })
      } finally {
        useJobsStore().list()
      }
    },

    async move (source: UUID, destination: UUID) {
      const notificationStore = useNotificationStore()
      try {
        await moveElement({ source, destination })
        this.remove(source)
        useNavigationStore().scheduledDeletion.add(source)
        notificationStore.notify({ type: 'success', text: 'Element moving has been scheduled.' })
      } catch (err) {
        notificationStore.notify({ type: 'error', text: errorParser(err) })
      } finally {
        useJobsStore().list()
      }
    },

    async createParent (childId: UUID, parentId: UUID) {
      try {
        await createParentElement(childId, parentId)
        this.listNeighbors(childId)
      } catch (err) {
        let message = err
        if (isAxiosError(err)) message = err.response?.data?.parent ?? err.response?.data?.child ?? err
        useNotificationStore().notify({ type: 'error', text: errorParser(message) })
      }
    },

    /**
     * Start, restart or continue fetching an element's children for up to `max` API result pages.
     *
     * If no pagination was stored in the `childrenPagination` state, starts from the first page.
     * If a pagination existed and a next page is available, resumes paginating.
     * If a pagination existed and there was no next page (i.e. all children have been listed),
     * requests for the first page with a `If-Modified-Since` HTTP header; if some children were modified,
     * it erases the element's children and restarts paginating from the first page.
     *
     * `max` sets the limit of API pages that should be iteratively retrieved.
     * It can be set to `Infinity` to load all pages.
     * `hasChildren` requests the API to fill the `has_children` attribute on elements.
     */
    async nextChildren (id: UUID, hasChildren = false, zone = true, max = ELEMENT_LIST_MAX_AUTO_PAGES) {
      try {
        let count = 0
        while (count < max) {
          const pagination = this.childrenPagination[id] ?? { number: 0, count: 0, previous: null, next: null }

          const payload: ElementChildrenListParameters = { id, page: (pagination.number + 1).toString() }
          if (pagination.number > 0 && !pagination.next) {
            // No next page; prepare to use If-Modified-Since and possibly restart the pagination
            payload.page = '1'
            payload.modifiedSince = pagination.retrievedOn
          }
          if (hasChildren) payload.with_has_children = 'true'
          if (!zone) payload.with_zone = 'false'

          const data = await listElementChildren(payload)

          if (payload.modifiedSince) {
            // HTTP 304: abort pagination here as this means nothing changed.
            if (!data) break
            // HTTP 200 with If-Modified-Since: erase the existing children and continue paginating from the first page
            this.resetChildren(id)
          } else if (!data) throw new Error('API returned HTTP 304 without a If-Modified-Since condition')

          // Save the children and new pagination data
          const { results, ...newPagination } = data
          const children = useClassificationStore().cleanElementList(results)
          this.addChildren(id, children)

          this.childrenPagination[id] = {
            ...newPagination,
            retrievedOn: new Date().toUTCString()
          }

          // No next page
          if (!newPagination.next) break
          count++
        }
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    /**
     * Fetch an element's parents recursively.
     */
    async getParentsBulk (id: UUID, corpus: CorpusLight) {
      try {
        let parentsPagination: Omit<PageNumberPagination<unknown>, 'results'> = { number: 0, count: 0, previous: null, next: null }
        do {
          const payload: ElementParentListParameters = {
            id,
            page: (parentsPagination.number + 1).toString(),
            with_zone: 'false',
            with_corpus: 'false',
            recursive: 'true'
          }

          // Save the parents and new pagination data
          const { results, ...newPagination } = await listElementParents(payload)

          // Save parents classifications
          const parents = useClassificationStore().cleanElementList(results)
          this.addParents(id, parents, corpus)
          parentsPagination = newPagination
        } while (parentsPagination.next)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async listNeighbors (id: UUID) {
      let neighbors: ElementNeighbor[] = []
      try {
        neighbors = await listElementNeighbors(id)
      } catch (err) {
        delete this.neighbors[id]
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        return
      }

      let nullParentError = false
      neighbors.forEach(neighbor => {
        /*
         * Detect and remove null values in each neighbor element's parents.
         * When the path array in ElementPath has IDs that point to elements that no longer exist,
         * it will cause a null to appear in the `parents` array.
         * We will still update the state, but throw an error after committing to show a notification
         * and get an explicit alert on Sentry.
         */
        if (!nullParentError && neighbor.path.some(parent => parent === null)) nullParentError = true
        neighbor.path = neighbor.path.filter(parent => parent !== null)
      })
      this.neighbors[id] = neighbors
      if (nullParentError) throw new Error('Unknown parent elements were found in neighbors')
    },

    async listElementDatasetSets (elementId: UUID, params: ElementSetListParameters = {}) {
      // Avoid listing datasets twice for an element
      if ((!params.page || params.page === 1) && this.elementDatasetSets?.[elementId]) return
      // List datasets containing a specific element through all pages
      try {
        const response = await listElementDatasetSets(elementId, params)

        // Progressively add results to the store
        if (elementId in this.elementDatasetSets) this.elementDatasetSets[elementId].push(...response.results)
        else this.elementDatasetSets[elementId] = response.results

        // Follow pagination without awaiting, until we fetched all the data
        if (response.next !== null) this.listElementDatasetSets(elementId, { ...params, page: response.number + 1 })
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    }
  },
  getters: {
    canWrite: state => (id: UUID): boolean => {
      const element = state.elements[id]
      return element && 'rights' in element && element.rights.includes('write')
    },
    canAdmin: state => (id: UUID): boolean => {
      const element = state.elements[id]
      return element && 'rights' in element && element.rights.includes('admin')
    },
    /**
     * Returns the first parent element of this element. Used to redirect to a parent when deleting the element
     */
    firstParentId: state => (id: UUID): UUID | undefined => {
      // Find all known paths for the element and reverse them so they start with the most direct parents
      const allPaths = state.neighbors?.[id]?.map(({ path }) => [...path].reverse()) ?? []
      /*
       * Prevent errors on null parents since ListElementNeighbors can return null parents for paths with 'ghost elements':
       * find the first defined parent in any path, starting with the most direct parents.
       * This uses zip().flat(1) so that we can look for all the direct parents in all paths first, then the grandparents, etc.
       * instead of going through each ancestry line one by one.
       * Lodash's zip() operates like itertools.zip_longest and not like the standard Python zip(),
       * so it can return arrays with undefined values if all paths are not the same length,
       * so we have to handle both null and undefined.
       */
      return zip(...allPaths).flat(1).find(parent => parent)?.id
    }
  }
})
