
import { mapState, mapActions } from 'pinia'
import { defineComponent } from 'vue'
import { errorParser } from '@/helpers'
import { useAuthStore } from '@/stores'

export default defineComponent({
  data: () => ({
    email: '',
    password: '',
    loading: false,
    error: null as string | null
  }),
  computed: {
    ...mapState(useAuthStore, ['user', 'isLoggedOn'])
  },
  methods: {
    ...mapActions(useAuthStore, { performLogin: 'login' }),
    async login () {
      this.loading = true
      try {
        await this.performLogin({
          email: this.email,
          password: this.password
        })
      } catch (err) {
        this.error = errorParser(err)
      } finally {
        this.loading = false
      }
    },
    tryRedirect () {
      if (!this.isLoggedOn) return
      if (typeof this.$route.query?.next === 'string') this.$router.replace(this.$route.query.next)
      else this.$router.push({ name: 'corpus-list' })
    }
  },
  watch: {
    // Use a watched property in case another request runs and updates the current user
    user: {
      immediate: true,
      handler: 'tryRedirect'
    }
  }
})
