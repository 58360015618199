import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container is-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CorpusHeader = _resolveComponent("CorpusHeader")!
  const _component_ElementNavigation = _resolveComponent("ElementNavigation")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CorpusHeader, { "corpus-id": _ctx.corpusId }, null, 8, ["corpus-id"]),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_ElementNavigation, {
        "corpus-id": _ctx.corpusId,
        query: _ctx.query,
        "onUpdate:query": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event))
      }, null, 8, ["corpus-id", "query"])
    ])
  ]))
}