
import { mapState, mapActions } from 'pinia'
import { defineComponent } from 'vue'
import { errorParser } from '@/helpers'
import { useAuthStore } from '@/stores'

export default defineComponent({
  data: () => ({
    error: null as string | null
  }),
  computed: {
    ...mapState(useAuthStore, ['isLoggedOn'])
  },
  methods: {
    ...mapActions(useAuthStore, ['logout'])
  },
  async mounted () {
    try {
      await this.logout()
    } catch (err) {
      this.error = errorParser(err)
      return
    }
    this.$router.replace({ name: 'login' })
  }
})
