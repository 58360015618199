
import { cloneDeep } from 'lodash'
import { mapState, mapActions, mapStores, mapWritableState } from 'pinia'
import { defineComponent, PropType } from 'vue'

import { errorParser, iiifUri } from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'
import {
  useDisplayStore,
  useClassificationStore,
  useCorporaStore,
  useAnnotationStore,
  useElementStore,
  useTreeStore,
  useAuthStore
} from '@/stores'
import { UUID } from '@/types'

import ChildElement from '@/components/Element/ChildElement.vue'
import ElementNavigation from '@/components/Navigation/ElementNavigation.vue'
import ElementHeader from '@/components/Element/ElementHeader.vue'
import DetailsPanel from '@/components/Element/DetailsPanel.vue'
import AnnotationPanel from '@/components/Element/AnnotationPanel.vue'
import ChildrenTree from '@/components/Navigation/ChildrenTree'
import InteractiveImage from '@/components/Image/InteractiveImage.vue'
import PanelHeader from '@/components/Element/PanelHeader.vue'
import { LocationQuery } from 'vue-router'

export default defineComponent({
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  props: {
    id: {
      type: String as PropType<UUID>,
      required: true
    },
    withHeader: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ElementNavigation,
    ElementHeader,
    DetailsPanel,
    AnnotationPanel,
    ChildrenTree,
    InteractiveImage,
    ChildElement,
    PanelHeader
  },
  data: () => ({
    loadingParents: true,
    elementError: null as string | null
  }),
  async created () {
    try {
      await this.load(this.id)
    } finally {
      this.loadingParents = false
    }
  },
  beforeRouteUpdate (to) {
    // Reset the tree type filter except when browsing neighbors
    if (to.name !== 'element-details' || typeof to.params.id !== 'string' || !this.isNeighbor(to.params.id)) this.typeFilter = null
    this.annotationStore.hoveredId = null
  },
  beforeRouteLeave () {
    this.typeFilter = null
  },
  computed: {
    ...mapState(useAuthStore, ['user', 'isLoggedOn', 'isVerified']),
    ...mapState(useElementStore, ['elements', 'links', 'parents', 'neighbors']),
    ...mapState(useClassificationStore, ['hasMLClasses']),
    ...mapState(useDisplayStore, ['displayDetails', 'displayFolderTree', 'displayAnnotationsTree']),
    ...mapWritableState(useTreeStore, ['typeFilter']),
    ...mapStores(useAnnotationStore, useCorporaStore, useElementStore),
    element () {
      return this.elements[this.id]
    },
    /**
     * The corporaMixin requires a corpusId property to be defined to be able to retrieve the current corpus.
     * It should either return a corpus ID, or null is there is no corpus.
     * Returning `undefined` can cause a warning in the console, as the mixin thinks the property may not be defined.
     * @returns {string | null}
     */
    corpusId () {
      return this.element?.corpus?.id ?? null
    },
    elementType () {
      return this.element && this.getType(this.element.type)
    },
    displayableChildren () {
      /*
       * Returns non-folder children with zones that should be displayed
       * when the current element is not a folder and has no zone
       * Those children are already loaded by the children tree.
       */
      if (!this.elementType || this.elementType.folder || this.hasImage) return []
      const elementPath = this.links[this.element.id]
      const childrenIds = (elementPath && [...elementPath.children]) || []
      return childrenIds
        .map(childId => this.elements[childId])
        .filter(child => child.zone !== null && !this.getType(child.type)?.folder)
    },
    hasImage (): boolean {
      return !!this.element?.zone
    },
    hasValidDimensions (): boolean {
      return !!this.element?.zone && this.element.zone.image.width > 0 && this.element.zone.image.height > 0
    },
    sourceImageUri (): string | undefined {
      if (!this.element.zone) return
      return iiifUri({ ...this.element.zone, polygon: [] })
    },
    elementDetailsId (): UUID | null {
      if (this.loadingParents && this.highlight) return null
      // Return the ID of an instantiated selected element, main element otherwise
      const selectedId = this.annotationStore.selectedElement?.id
      // Defaults to the main element
      if (!selectedId || selectedId === 'created-polygon') return this.element.id
      return selectedId
    },
    showTree (): boolean {
      if (!this.elementType || this.elementType.folder === undefined) return false
      return (!this.elementType.folder && this.displayAnnotationsTree) || (this.elementType.folder && this.displayFolderTree)
    },
    highlight (): UUID | null {
      const highlight = this.$route.query.highlight
      return typeof highlight === 'string' ? highlight : null
    },
    isInteractive (): boolean {
      return !this.elementType?.folder && this.hasImage
    },
    query: {
      get (): LocationQuery {
        return this.$route.query
      },
      set (query: LocationQuery) {
        this.$router.push({ ...this.$route, query })
      }
    }
  },
  methods: {
    ...mapActions(useClassificationStore, ['listCorpusMLClasses']),
    async retrieveMLClasses () {
      if (!this.corpusId || !this.element) return
      /*
       * The ML class creation input does lazy requests to fetch ML classes,
       * but we still want to hide this input when there are zero ML classes available.
       * When an element is loaded, we call this.retrieveMLClasses() on this component
       * which ensures useClassificationStore().hasMLClasses is set to show or hide the input.
       */
      if (this.hasMLClasses[this.corpusId] === undefined) {
        this.listCorpusMLClasses(this.corpusId, { page: 1 })
      }
    },
    async load (id: UUID) {
      try {
        await this.elementStore.get(id)
      } catch (e) {
        this.elementError = errorParser(e)
        return
      }

      if (!this.element || !this.element.corpus) return
      await this.corporaStore.get(this.element.corpus.id)
      await this.retrieveMLClasses()

      if (!this.isInteractive || !this.highlight) return
      // Load the parents of the highlighted element if they were not already available
      if (this.corpus && !this.parents[this.highlight]) await this.elementStore.getParentsBulk(this.highlight, this.corpus)
      // Check that this element is a parent of the highlighted element
      if (!this.parents[this.highlight] || !this.parents[this.highlight].includes(this.element.id)) return
      // Load the highlighted element
      if (!this.elements[this.highlight]) await this.elementStore.get(this.highlight)
      // Select it
      this.annotationStore.selectedElement = cloneDeep(this.elements[this.highlight])
    },
    isNeighbor (id: UUID): boolean {
      return id === this.id || (this.neighbors[this.id]?.flatMap(({ previous, next }) => [previous?.id, next?.id]).includes(id) ?? false)
    }
  },
  watch: {
    async id (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.annotationStore.selectedElement = null
        this.loadingParents = true
        try {
          await this.load(newValue)
        } finally {
          this.loadingParents = false
        }
      }
    }
  }
})
