
import { mapState, mapActions } from 'pinia'
import { defineComponent } from 'vue'
import { useAuthStore } from '@/stores'

export default defineComponent({
  computed: {
    ...mapState(useAuthStore, ['user', 'isVerified'])
  },
  methods: {
    ...mapActions(useAuthStore, ['sendVerificationEmail']),
    goBack () {
      window.history.back()
    }
  }
})
