
import { mapActions } from 'pinia'
import { useAuthStore } from '@/stores'
import { defineComponent } from 'vue'

export default defineComponent({
  data: () => ({
    email: '',
    error: null as unknown,
    success: false,
    loading: false
  }),
  methods: {
    ...mapActions(useAuthStore, ['sendResetEmail']),
    async submit () {
      this.success = false
      this.error = null
      this.loading = true
      try {
        await this.sendResetEmail({ email: this.email })
        this.success = true
      } catch (err) {
        this.error = err
      } finally {
        this.loading = false
      }
    }
  }
})
