
import Mousetrap from 'mousetrap'
import { mapState, mapStores } from 'pinia'
import { defineComponent, PropType } from 'vue'

import { ElementNeighbor } from '@/api'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useAuthStore, useElementStore } from '@/stores'
import { Element, ElementBase, ElementLight, UUID } from '@/types'

import EditableName from '@/components/EditableName.vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
      validator: element => (
        element !== null &&
        typeof element === 'object' &&
        'id' in element &&
        typeof element.id === 'string' &&
        'corpus' in element &&
        element.corpus !== null &&
        typeof element.corpus === 'object' &&
        'id' in element.corpus &&
        'type' in element &&
        typeof element.type === 'string'
      )
    },
    neighbor: {
      type: Object as PropType<ElementNeighbor>,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Whether to assign the Ctrl+Left and Ctrl+Right shortcuts to browsing the previous and next elements on this path.
     */
    keyboardShortcuts: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EditableName
  },
  mounted () {
    if (this.keyboardShortcuts) {
      Mousetrap.bind('ctrl+left', () => {
        if (this.neighbor?.previous) this.$router.push(this.pathLink(this.neighbor.previous.id))
      })
      Mousetrap.bind('ctrl+right', () => {
        if (this.neighbor?.next) this.$router.push(this.pathLink(this.neighbor.next.id))
      })
    }
  },
  beforeUnmount () {
    if (this.keyboardShortcuts) {
      Mousetrap.unbind('ctrl+left')
      Mousetrap.unbind('ctrl+right')
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isVerified']),
    ...mapStores(useElementStore),
    corpusId (): UUID | null {
      return this.element.corpus?.id ?? null
    }
  },
  methods: {
    elementLink (eltId: UUID | null | undefined, routeName = 'element-details', parent: ElementLight | null = null): RouteLocationRaw {
      /* Returns a link to a route (default to element's details) from an element ID */
      if (!eltId) return {}
      return {
        name: routeName,
        params: { id: eltId },
        query: { from: parent?.id }
      }
    },
    /**
     * Returns a link to same route but for another element ID.
     * When available, this path's direct parent ID will be used as the ?from= query parameter.
     */
    pathLink (eltId: UUID | null | undefined) {
      return this.elementLink(eltId, this.$route.name?.toString(), this.neighbor?.path[this.neighbor?.path?.length - 1])
    },
    /**
     * Used by the EditableName component
     */
    async renameElement (name: string) {
      await this.elementStore.patch(this.element.id, { name })
    }
  }
})
