import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["viewBox"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElementZone = _resolveComponent("ElementZone")!

  return (_openBlock(), _createElementBlock("svg", {
    viewBox: _ctx.svgBox,
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    (_ctx.hasValidDimensions)
      ? (_openBlock(), _createElementBlock("g", {
          key: 0,
          style: _normalizeStyle(_ctx.innerStyle)
        }, [
          _createElementVNode("image", _mergeProps(_ctx.boxCoords, { href: _ctx.source }), null, 16, _hoisted_2),
          _createVNode(_component_ElementZone, {
            element: _ctx.element,
            color: _ctx.elementColor,
            active: false
          }, null, 8, ["element", "color"])
        ], 4))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}