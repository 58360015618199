
import { isEqual } from 'lodash'
import { mapState, mapStores } from 'pinia'
import { UUID_REGEX } from '@/config'
import { corporaMixin } from '@/mixins'
import Paginator from '@/components/Paginator.vue'
import SearchForm from '@/components/Search/Form.vue'
import SearchResult from '@/components/Search/Result.vue'
import { useCorporaStore, useSearchStore } from '@/stores'
import { defineComponent } from 'vue'
import { Corpus, UUID } from '@/types'
import { SearchQuery } from '@/types/search'

export default defineComponent({
  components: {
    Paginator,
    SearchForm,
    SearchResult
  },
  mixins: [
    corporaMixin
  ],
  props: {
    corpusId: {
      type: String,
      default: '',
      validator: value => typeof value === 'string' && (value === '' || UUID_REGEX.test(value))
    }
  },
  mounted () {
    useSearchStore().$reset()

    // There can be a random race condition causing the corpora to not always be listed
    if (!this.corporaStore.loaded) this.corporaStore.list()

    if (this.selectedCorpusId) this.search(this.selectedCorpusId, this.$route.query)
    // Support the older search URLs that used ?corpus=… instead of a path parameter
    else if (this.$route.query?.corpus) this.selectedCorpusId = this.$route.query.corpus.toString()
  },
  beforeRouteUpdate (to) {
    if (!to.params.corpusId || Array.isArray(to.params.corpusId)) return
    this.search(to.params.corpusId, to.query)
  },
  data: () => ({
    loading: false,
    hideResults: false
  }),
  computed: {
    ...mapStores(useCorporaStore),
    ...mapState(useSearchStore, ['documents']),
    indexableCorpora (): Corpus[] {
      return Object.values(this.corpora).filter(c => c.indexable)
    },
    selectedCorpusId: {
      get (): UUID | '' {
        return this.corpusId
      },
      set (corpusId: UUID) {
        if (this.corpusId === corpusId) return
        this.$router.push({ ...this.$route, params: { corpusId }, query: { query: '*' } })
      }
    },
    query: {
      get () {
        return this.$route.query
      },
      set (query: SearchQuery) {
        const newQuery = {
          ...query,
          only_facets: (query.only_facets || false).toString()
        }

        /*
         * In case no source is selected, the backend would return from any source.
         * We want to avoid that in the frontend by requesting at least 1 source.
         */
        if (!newQuery.sources?.length) {
          this.hideResults = true
          useSearchStore().$reset()
          return
        } else {
          this.hideResults = false
        }

        if (!isEqual(this.$route.query, newQuery)) {
          // The query has changed, update the route.
          this.$router.push({ ...this.$route, query: newQuery })
        } else if (this.corpusId) {
          // If the search button is hit, the query hasn't changed, and a corpus is selected, re-run the search
          this.search(this.corpusId, query)
        }
      }
    },
    page: {
      get (): number {
        if (!this.query.page) return 1
        return Number.parseInt(this.query.page.toString(), 10) || 1
      },
      set (page: number) {
        this.query = { ...this.query, page: page.toString() }
      }
    }
  },
  methods: {
    async search (corpusId: UUID, query: Partial<SearchQuery> = {}) {
      if (!corpusId) corpusId = this.corpusId
      if (this.loading || !UUID_REGEX.test(corpusId)) return

      this.loading = true
      try {
        await useSearchStore().search(corpusId, {
          // Use `?query=*` by default because a search query is mandatory
          query: '*',
          ...query
        })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    corpusId (newValue: UUID | '') {
      // Block using a corpus ID that does not exist or that is not indexable
      if (newValue && !this.corpus?.indexable) this.selectedCorpusId = ''
      // Reset results when clicking 'Search' in the NavBar
      if (!newValue) useSearchStore().$reset()
    }
  }
})
