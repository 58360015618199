
import { mapState } from 'pinia'
import { isEmpty } from 'lodash'
import Mousetrap from 'mousetrap'
import { defineComponent } from 'vue'
import { RouteLocationRaw } from 'vue-router'

import { KEYBOARD_HELP } from '@/help'
import { useAuthStore, useJobsStore, useSelectionStore } from '@/stores'

import HelpModal from './HelpModal.vue'
import JobsModal from './Jobs/Modal.vue'

export default defineComponent({
  components: {
    HelpModal,
    JobsModal
  },
  data: () => ({
    openedBurger: false,
    jobsModal: false,
    keyboardModal: false,
    userDropdown: false,
    KEYBOARD_HELP
  }),
  mounted () {
    Mousetrap.bind('?', () => { this.keyboardModal = true })
  },
  computed: {
    ...mapState(useAuthStore, ['user', 'isLoggedOn', 'isVerified', 'isAdmin', 'hasFeature']),
    ...mapState(useSelectionStore, {
      selectedCount: 'loaded',
      selectedTotal: 'count'
    }),
    ...mapState(useJobsStore, ['jobs']),
    registerRoute (): RouteLocationRaw {
      if (this.$route.name === 'register') return this.$route
      return { name: 'register', query: { next: this.$route.fullPath } }
    },
    loginRoute (): RouteLocationRaw {
      if (this.$route.name === 'login') return this.$route
      return { name: 'login', query: { next: this.$route.fullPath } }
    }
  },
  methods: {
    isEmpty,
    toggleBurger () {
      this.openedBurger = !this.openedBurger
    },
    toggleUserDropdown () {
      this.userDropdown = !this.userDropdown
      /*
       * Cause the dropdown to close when clicking anywhere
       * Note that this method must be linked to the element using v-on:click.stop,
       * otherwise the event's propagation will cause the dropdown to be immediately closed.
       */
      if (this.userDropdown) document.addEventListener('click', this.toggleUserDropdown)
      else document.removeEventListener('click', this.toggleUserDropdown)
    }
  }
})
