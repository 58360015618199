
import { defineComponent, PropType } from 'vue'
import { WORKER_TYPE_COLORS } from '@/config'
import { truncateMixin } from '@/mixins'
import { WorkerVersion } from '@/types/worker'

export default defineComponent({
  props: {
    workerVersion: {
      type: Object as PropType<WorkerVersion>,
      required: true
    }
  },
  mixins: [
    truncateMixin
  ],
  computed: {
    workerClass (): string {
      return WORKER_TYPE_COLORS[this.workerVersion.worker.type]?.cssClass ?? WORKER_TYPE_COLORS.default.cssClass
    }
  }
})
