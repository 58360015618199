import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fb43fb7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "is-inline-block" }
const _hoisted_2 = {
  key: 0,
  class: "placeholdertext notification is-danger"
}
const _hoisted_3 = {
  key: 1,
  class: "placeholdertext"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("figure", _hoisted_1, [
    _createElementVNode("div", {
      class: "image",
      style: _normalizeStyle(_ctx.imageStyle)
    }, [
      (_ctx.imgError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Media loading failed"))
        : (!_ctx.loaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Loading..."))
          : _createCommentVNode("", true),
      _createElementVNode("img", {
        src: _ctx.source,
        onLoad: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loaded = true)),
        onError: _cache[1] || (_cache[1] = ($event: any) => (_ctx.imgError = true)),
        style: _normalizeStyle(_ctx.imgStyle)
      }, null, 44, _hoisted_4)
    ], 4),
    _createVNode(_component_router_link, {
      to: { name: 'element-details', params: { id: _ctx.element.id } },
      class: "is-pulled-left",
      title: `${_ctx.element.name} (${_ctx.typeName(_ctx.element.type)})`
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.truncateShort(_ctx.element.name)) + " (" + _toDisplayString(_ctx.truncateShort(_ctx.typeName(_ctx.element.type))) + ") ", 1)
      ]),
      _: 1
    }, 8, ["to", "title"])
  ]))
}