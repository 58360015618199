
import { mapState, mapActions } from 'pinia'

import { truncateMixin, corporaMixin } from '@/mixins'
import { useDisplayStore, useClassificationStore, useSelectionStore, useAuthStore } from '@/stores'

import DeleteModal from '@/components/SingleDeletionModal.vue'
import PreviewDropdown from './PreviewDropdown.vue'
import { defineComponent, PropType } from 'vue'
import { ElementBase, ElementType, UUID } from '@/types'
import { ProcessElementList } from '@/types/process'
import { LocationQueryRaw, RouteLocationRaw } from 'vue-router'

export default defineComponent({
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    DeleteModal,
    PreviewDropdown
  },
  props: {
    element: {
      type: Object as PropType<ElementBase | ProcessElementList>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useSelectionStore, ['selection']),
    ...mapState(useDisplayStore, ['elementsTableLayout', 'displayElementClasses']),
    ...mapState(useClassificationStore, ['classifications']),
    ...mapState(useAuthStore, ['isLoggedOn', 'hasFeature']),
    elementRoute (): RouteLocationRaw {
      const query: LocationQueryRaw = (this.elementsTableLayout ? { display: 'table' } : {})
      if (this.$route?.params?.id) query.from = this.$route.params.id
      return { name: 'element-details', params: { id: this.element.id }, query }
    },
    corpusId (): UUID | null {
      if (!this.element.corpus) return null
      return this.element.corpus.id
    },
    elementType (): ElementType | null {
      if ('type' in this.element) return this.getType(this.element.type)
      else if (this.element.type_id && this.corpus?.types) return Object.values(this.corpus.types).find(({ id }) => 'type_id' in this.element && id === this.element.type_id) ?? null
      return null
    },
    elementTypeName (): string {
      return this.elementType?.display_name ?? this.elementType?.slug ?? ('type' in this.element ? this.element.type : '')
    },
    selected (): boolean {
      return this.corpusId !== null && this.selection[this.corpusId]?.includes(this.element.id)
    },
    classDisplay (): string | null {
      if (!this.displayElementClasses || !Array.isArray(this.classifications[this.element.id])) return null
      if (!this.classifications[this.element.id].length) return '—'
      const names = this.classifications[this.element.id].map(({ ml_class: { name } }) => name)
      names.sort()
      return names.join(', ')
    },
    /**
     * The backend generates thumbnail URLs for all folder elements, or for non-folder elements with a zone.
     * Since most folder elements do not have a zone anyway, we only display the preview modal for non-folder
     * elements with a thumbnail URL.
     */
    hasPreview (): boolean {
      return !this.elementType?.folder && 'thumbnail_url' in this.element && this.element.thumbnail_url !== null
    }
  },
  methods: {
    ...mapActions(useSelectionStore, ['select', 'unselect']),
    toggleSelection () {
      if (this.element.corpus === null) return
      // This pleases TypeScript by making it understand that the corpus is not null on this element
      const element = { ...this.element, corpus: { ...this.element.corpus } }
      if (this.selected) this.unselect(element)
      else this.select([element])
    }
  }
})
