
import { mapActions, mapState, mapStores } from 'pinia'
import { defineComponent, PropType } from 'vue'

import { EXPORT_FORMATS } from '@/config'
import { corporaMixin, truncateMixin } from '@/mixins'
import { createProcessRedirect } from '@/helpers'
import {
  useAuthStore,
  useClassificationStore,
  useElementStore,
  useSelectionStore
} from '@/stores'
import { ElementLight, UUID } from '@/types'

import ExportProcessModal from '@/components/ExportProcessModal.vue'
import MLClassSelect from '@/components/MLClassSelect.vue'
import Modal from '@/components/Modal.vue'
import DeleteResultsModal from '@/components/Process/Workers/DeleteResultsModal.vue'
import DatasetFromSelectionModal from './DatasetFromSelectionModal.vue'
import FolderPicker from './FolderPicker'
import ElementList from './ElementList.vue'

export default defineComponent({
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  components: {
    ElementList,
    DeleteResultsModal,
    MLClassSelect,
    Modal,
    FolderPicker,
    DatasetFromSelectionModal,
    ExportProcessModal
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true
    }
  },
  data: () => ({
    selectedNewClassification: '',
    isSavingNewClassification: false,
    addClassificationModal: false,
    validateClassificationModal: false,
    allValidated: false,
    deleteModal: false,
    deleteLoading: false,
    deleteResultsModal: false,
    moveSelectionModal: false,
    moveLoading: false,
    createParentModal: false,
    createParentLoading: false,
    pickedFolder: null as ElementLight | null,
    datasetSelectionModal: false,
    EXPORT_FORMATS
  }),
  mounted () {
    if (this.hasMLClasses[this.corpusId] === undefined) {
      this.listCorpusMLClasses(this.corpusId, { page_size: 1 })
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isVerified']),
    ...mapState(useElementStore, { storeElements: 'elements' }),
    ...mapState(useClassificationStore, ['hasMLClasses']),
    ...mapStores(useSelectionStore),
    canCreate (): boolean {
      return this.corpus !== null && this.isVerified && this.canWrite(this.corpus)
    },
    canExecute (): boolean {
      return this.corpus !== null && this.isVerified && this.canAdmin(this.corpus)
    },
    canExport (): boolean {
      return this.corpus !== null && this.isVerified && this.canAdmin(this.corpus)
    },
    createDisabledTitle (): string {
      let prefix = 'You do not have the required right'
      if (!this.isVerified) prefix = 'Your email address should be verified'
      else if (!this.canCreate) prefix = 'You must have a contributor access to this project in order'
      else if (this.allValidated) return 'All elements have already been validated'
      return `${prefix} to perform this action.`
    },
    executeDisabledTitle (): string {
      let prefix = 'You do not have the required right'
      if (!this.isVerified) prefix = 'Your email address should be verified'
      else if (!this.canExecute) prefix = 'You must have an admin access to this project in order'
      return `${prefix} to perform this action.`
    },
    elements () {
      return (this.selectionStore.selection[this.corpusId] ?? [])
        .map(id => this.storeElements[id])
        /*
         * Even if the element ID is in the selection store,
         * it might not be in the elements store, so we would get an `undefined`
         */
        .filter(element => element)
    },
    selectedElementIds (): UUID[] {
      return this.elements.map(item => item.id)
    },
    hasContribPrivilege (): boolean {
      return this.isVerified && this.corpus !== null && this.canWrite(this.corpus)
    }
  },
  methods: {
    ...mapActions(useClassificationStore, ['listCorpusMLClasses']),
    createProcess () {
      if (!this.canCreate) return
      const payload = { corpus: this.corpusId, selection: true }
      createProcessRedirect(this.$router, payload)
    },
    async createClassification () {
      this.isSavingNewClassification = true
      try {
        await this.selectionStore.createClassifications(this.corpusId, this.selectedNewClassification)
      } finally {
        this.selectionStore.get()
        this.selectedNewClassification = ''
        this.isSavingNewClassification = false
        this.addClassificationModal = false
      }
    },
    async validateClassification () {
      try {
        await this.selectionStore.validateClassifications(this.corpusId)
      } finally {
        this.validateClassificationModal = false
        this.allValidated = true
      }
    },
    async performDelete () {
      if (!this.canExecute) return
      this.deleteLoading = true
      try {
        await this.selectionStore.deleteElements(this.corpusId)
        this.deleteModal = false
      } finally {
        this.deleteLoading = false
      }
    },
    async performMove () {
      if (!this.hasContribPrivilege || !this.pickedFolder) return
      this.moveLoading = true
      try {
        await this.selectionStore.move(this.corpusId, this.pickedFolder.id)
        this.moveSelectionModal = false
      } finally {
        this.moveLoading = false
      }
    },
    async performCreateParent () {
      if (!this.hasContribPrivilege || !this.pickedFolder) return
      this.createParentLoading = true
      try {
        await this.selectionStore.createParent(this.corpusId, this.pickedFolder.id)
        this.createParentModal = false
      } finally {
        this.createParentLoading = false
      }
    }
  }
})
